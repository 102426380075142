import React, { useEffect, FormEvent } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../../redux/app/store'
import { getCurrentArea, resetArea, setCurrentArea } from '../../../../redux/features/areas/areaSlice'
import { IoClose } from 'react-icons/io5'
import { putArea } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Button/Button'
import Form from '../Form/Form'
import Modal from '../../../../components/Modal/Modal'
import Pavilions from '../Pavilions/Pavilions'

const EditArea = () => {
  const dispatch = useDispatch<AppDispatch>()
  const area = useSelector(getCurrentArea)

  useEffect(() => {
    const areaId = localStorage.getItem("areaId") || ""
    dispatch(setCurrentArea(areaId))

    return (() => {
      localStorage.removeItem("areaId")
      dispatch(resetArea())
    })
  }, [])

  const hadleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(putArea()).unwrap()
      .then(() => {
        dispatch(updateState({ name: "editAreaModal", value: false }))
        localStorage.removeItem("areaId")
        showAlert("Area editada", "success")
      }).catch(() => showAlert("Error al editar el area", "error"))
  }

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "editAreaModal", value: false }))}>
      <SC.CustomContentWrapper onSubmit={hadleSubmit}>
        <Form />
        <Pavilions pavilions={area.pavilions} />
        <Button
          text='Editar'
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          marginTop="128px"
          onClick={() => { }}
        />
        <button className="close-btn modal-wrapper"><IoClose size={35} className="modal-wrapper" /></button>
      </SC.CustomContentWrapper>
    </Modal>
  )
}

export default EditArea