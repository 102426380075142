import React from 'react'
import * as SC from './style'

interface ConfigTableProps {
  gridColumn?: string,
  children: JSX.Element | JSX.Element[]
}

const ConfigTable = ({ gridColumn, children }: ConfigTableProps) => {
  return (
    <SC.ConfigTableWrapper
      gridColumn={gridColumn}
    >
      {children}
    </SC.ConfigTableWrapper>
  )
}

export default ConfigTable