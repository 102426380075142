import React from 'react'
import * as SC from './style'
import { ADMIN_DASHBOARD } from '../../../../../routes'
import { IAssistantStatistics } from '../../../../../redux/features/assistants/interfaces'
import { useNavigate } from 'react-router-dom'

interface PersonalProps {
  assistansStatistic: IAssistantStatistics
  max: number
}

const Personal = ({ assistansStatistic, max }: PersonalProps) => {
  const navigate = useNavigate()
  const { fullname, totalTransfers, id } = assistansStatistic

  const handlePersonalClick = () => {
    navigate(`${ADMIN_DASHBOARD}/${fullname.replaceAll(" ", "-")}/${id}`)
  }

  return (
    <SC.PersonalWrapper onClick={handlePersonalClick}>
      <SC.Name>{fullname}</SC.Name>
      <SC.Percentage width={Math.round(totalTransfers * 100 / max) || 0}></SC.Percentage>
    </SC.PersonalWrapper>
  )
}

export default Personal