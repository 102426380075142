import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addAssistantToAssignment, deleteArea, deleteAssistantFromAssignment, getAssignmets, postArea } from "../../../services/configuration";
import { IAssignAssistant, IGetAssignments } from "./interfaces";
import { RootState } from "../../app/store";
import { IArea } from "../areas/interfaces";

interface IAssignmentState {
  isLoading: boolean;
  assignments: IGetAssignments;
}

const initialState: IAssignmentState = {
  isLoading: false,
  assignments: {
    results: [],
    totalResults: 0,
    totalPages: 0,
  }
}

export const assignmentSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssignmets.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssignmets.fulfilled, (state, { payload }: PayloadAction<IGetAssignments>) => {
        state.assignments = payload
        state.isLoading = false
      })
      .addCase(getAssignmets.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(addAssistantToAssignment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addAssistantToAssignment.fulfilled, (state, { payload }: PayloadAction<{ areaId: string, aux: IAssignAssistant }>) => {
        const { areaId, aux } = payload
        const index = state.assignments.results.findIndex((item) => item.id === areaId)
        state.assignments.results[index].assistants.unshift(aux)
        state.isLoading = false
      })
      .addCase(addAssistantToAssignment.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteAssistantFromAssignment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAssistantFromAssignment.fulfilled, (state, { payload }: PayloadAction<{ area: string, assistant: string }>) => {
        const { area, assistant } = payload
        const index = state.assignments.results.findIndex((item) => item.id === area)
        state.assignments.results[index].assistants = state.assignments.results[index].assistants.filter((item) => item.id !== assistant)
        state.isLoading = false
      })
      .addCase(deleteAssistantFromAssignment.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteArea.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.assignments.results = state.assignments.results.filter((item) => item.id !== payload)
      })
    builder
      .addCase(postArea.fulfilled, (state, { payload }: PayloadAction<IArea>) => {
        const currentPage = Number(localStorage.getItem("assignment-cur-page"))

        if (state.assignments.results.length < 5) {
          state.assignments.results.push({ ...payload, assistants: [] })
        } else if ((state.assignments.totalPages + 1) === currentPage + 1) {
          state.assignments.totalPages = state.assignments.totalPages + 1
        }
      })
  }
})

export const getIsLoading = (store: RootState) => store.assignments.isLoading
export const getAssignmetsState = (store: RootState) => store.assignments.assignments

export const { } = assignmentSlice.actions
export default assignmentSlice.reducer