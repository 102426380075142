import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getApplicantTransfers, getAssistantHistoricalTransfers, getTransferById, getTransfers, getTransfersByArea, postCodeBlue, postReturnTransfer, postTransfer } from "../../../services/transfers";
import { IGetApplicantTransfers, IGetHistoricalTransfers, IGetPendingTransfers, IGetTransfers, IRequest990Transfer, ITransferDetails } from "./interface";
import { request990TransferInitialState, transferInitialState } from "./state";
import { RootState } from "../../app/store";

interface ITrasnsferState {
  isLoading: boolean,
  transfers: IGetTransfers,
  transfer: ITransferDetails,
  applicantTransfer: IGetApplicantTransfers,
  request990Transfer: IRequest990Transfer,
  historicalTransfers: IGetHistoricalTransfers,
  pendingTransfers: IGetPendingTransfers
}

const initialState: ITrasnsferState = {
  isLoading: false,
  transfers: {
    results: [],
    totalPages: 0,
    totalResults: 0,
  },
  transfer: transferInitialState,
  applicantTransfer: {
    results: [],
    totalPages: 1,
    totalResults: 0
  },
  request990Transfer: request990TransferInitialState,
  historicalTransfers: {
    results: [],
    totalPages: 1,
    totalResults: 0
  },
  pendingTransfers: {
    results: [],
    totalPages: 1,
    totalResults: 0
  }
}

const transferSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    updateTransfer: (state, { payload }: PayloadAction<{ name: string, value: string }>) => {
      const { name, value } = payload

      state.request990Transfer = {
        ...state.request990Transfer,
        [name]: value
      }
    },
    resetTransfer: (state) => {
      state.transfer = initialState.transfer
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransfers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTransfers.fulfilled, (state, { payload }: PayloadAction<IGetTransfers>) => {
        state.transfers = payload
        state.isLoading = false
      })
      .addCase(getTransfers.rejected, (state) => {
        state.transfers = initialState.transfers
        state.isLoading = false
      })
    builder
      .addCase(getTransferById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTransferById.fulfilled, (state, { payload }: PayloadAction<ITransferDetails>) => {
        state.transfer = payload
        state.isLoading = false
      })
      .addCase(getTransferById.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(getApplicantTransfers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getApplicantTransfers.fulfilled, (state, { payload }: PayloadAction<IGetApplicantTransfers>) => {
        state.applicantTransfer = payload
        state.isLoading = false
      })
      .addCase(getApplicantTransfers.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(postTransfer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postTransfer.fulfilled, (state, { payload }: PayloadAction<ITransferDetails>) => {
        state.transfer = payload
        state.isLoading = false
      })
      .addCase(postTransfer.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(postCodeBlue.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postCodeBlue.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(postCodeBlue.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(getAssistantHistoricalTransfers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssistantHistoricalTransfers.fulfilled, (state, { payload }: PayloadAction<IGetHistoricalTransfers>) => {
        state.historicalTransfers = payload
        state.isLoading = false
      })
      .addCase(getAssistantHistoricalTransfers.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(postReturnTransfer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postReturnTransfer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(postReturnTransfer.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(getTransfersByArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTransfersByArea.fulfilled, (state, { payload }: PayloadAction<IGetPendingTransfers>) => {
        state.pendingTransfers = payload
        state.isLoading = false
      })
      .addCase(getTransfersByArea.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.transfers.isLoading
export const getTransferState = (store: RootState) => store.transfers.transfers
export const getTransfer = (store: RootState) => store.transfers.transfer
export const getApplicantTransfer = (store: RootState) => store.transfers.applicantTransfer
export const getRequest990Transfer = (store: RootState) => store.transfers.request990Transfer
export const getHistoricalTransfers = (store: RootState) => store.transfers.historicalTransfers
export const getPendingTransfers = (store: RootState) => store.transfers.pendingTransfers

export const { updateTransfer, resetTransfer } = transferSlice.actions
export default transferSlice.reducer