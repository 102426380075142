import styled from "styled-components";

interface ButtonProps {
  active: boolean
}

export const Button = styled.button<ButtonProps>`
  background: ${({ active }) => active ? "var(--light-blue)" : "var(--grey)"};
  border: none;
  box-shadow: 0px 3px 6px var(--shadow);
  color: ${({ active }) => active ? "var(--white)" : "var(--text)"};
  cursor: pointer;
  font-size: 19px;
  font-weight: 400;
  padding: 1% 2%;
  transition: all 0.2s ease;
  
  :first-child {
    border-radius: 8px 0 0 8px;
  }

  :last-child {
    border-radius: 0 8px 8px 0;
  }
`