import React from 'react'
import * as AS from '../../../AreaTable/Actions/style'
import { AppDispatch } from '../../../../../redux/app/store'
import { deleteTravelTime } from '../../../../../services/configuration'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { showAlert } from '../../../../../utils/alerts/showAlert'
import { updateState } from '../../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch } from 'react-redux'
import { showConfirmAlert } from '../../../../../utils/alerts/confirmAlert'

interface ActionsProps {
  travelTimeId: string,
  setShowActions: (showActions: false) => void
}

const Action = ({ travelTimeId, setShowActions }: ActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleEditClick = () => {
    setShowActions(false)
    localStorage.setItem("travelTimeId", travelTimeId)
    dispatch(updateState({ name: "editTransferTime", value: true }))
  }

  const handleDeleteClick = async () => {
    setShowActions(false)

    const isConfirmed = await showConfirmAlert({
      title: "Estás seguro/a?",
      text: "Esta acción es irreversible",
      icon: "question"
    })

    if (!isConfirmed) return

    dispatch(deleteTravelTime(travelTimeId)).unwrap()
      .then(() => showAlert("Tiempo de traslado eliminado", "success"))
      .catch(() => showAlert("Error al eliminar tiempo de traslado", "error"))
  }

  return (
    <AS.ActionsWrapper>
      <AS.Btn single={true} onClick={handleEditClick}>Editar <FiEdit size={35} /></AS.Btn>
      <AS.Btn onClick={handleDeleteClick}>Eliminar <FiTrash size={35} /></AS.Btn>
    </AS.ActionsWrapper>
  )
}

export default Action