import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLoggedUser } from "../../../services/users";
import { IUser } from "./interfaces";
import { RootState } from "../../app/store";
import { userInitialState } from "./state";

interface IUserState {
  isLoading: boolean;
  user: IUser;
}

const initialState: IUserState = {
  isLoading: false,
  user: userInitialState
}

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoggedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLoggedUser.fulfilled, (state, { payload }: PayloadAction<IUser>) => {
        state.user = payload
        state.isLoading = false
      })
      .addCase(getLoggedUser.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.users.isLoading
export const getUser = (store: RootState) => store.users.user

export const { } = userSlice.actions
export default userSlice.reducer