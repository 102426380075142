import React, { useEffect, useState } from 'react'
import { getIsLoading, getTransferState } from '../../../redux/features/transfers/transferSlice'
import { getTransfers } from '../../../services/transfers'
import { IOptions } from '../../../redux/features/transfers/interface'
import { useDispatch, useSelector } from 'react-redux'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Options from './Options/Options'
import Pagination from '../../../components/Pagination/Pagination'
import TransfersTable from './TransfersTable/TransfersTable'
import { getPermissions } from '../../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../../components/Unauthorized/Unauthorized'

const Transfers = () => {
  const dispatch = useDispatch()
  const { access, admin, administrator } = useSelector(getPermissions)
  const transfers = useSelector(getTransferState)
  const isLoading = useSelector(getIsLoading)
  const [data, setData] = useState<IOptions>({
    initial_date: "",
    final_date: "",
    type_transfer: "",
    page: 1,
    quantity: 8
  })

  useEffect(() => {
    dispatch(getTransfers({ ...data }))
  }, [data.page])

  const handlePageChange = ({ selected }: any) => {
    setData({
      ...data,
      page: selected + 1
    })
  }

  return (
    <>
      {
        administrator || access || admin ? (
          <>
            <Options data={data} setData={setData} />
            <TransfersTable />
            <Pagination pageCount={transfers.totalPages} page={data.page} marginTop="50px" onPageChange={handlePageChange} />
          </>
        ) : (
          <Unauthorized />
        )
      }
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Transfers