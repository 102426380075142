import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface IRequestorState {
  asignTransferModal: boolean,
  requestTransferModal: boolean,
  noAssistantModal: boolean
}

const initialState: IRequestorState = {
  asignTransferModal: false,
  requestTransferModal: false,
  noAssistantModal: false
}

const requestorSlice = createSlice({
  name: "requestor",
  initialState,
  reducers: {
    updateState: (state, { payload }: PayloadAction<{ name: string, value: any }>) => {
      const { name, value } = payload
      // @ts-ignore
      state[name] = value
    }
  }
})

export const getAsignTransferModal = (store: RootState) => store.requestor.asignTransferModal
export const getRequestTransferModal = (store: RootState) => store.requestor.requestTransferModal
export const getNoAssistantModal = (store: RootState) => store.requestor.noAssistantModal

export const { updateState } = requestorSlice.actions
export default requestorSlice.reducer