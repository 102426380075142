import React from 'react'
import * as MS from '../style'
import { getIsEditingAssignment } from '../../../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { GoDiffRemoved } from 'react-icons/go'
import { IAssignment } from '../../../../../../redux/features/assignments/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAssistantFromAssignment } from '../../../../../../services/configuration'
import { AppDispatch } from '../../../../../../redux/app/store'
import { showAlert } from '../../../../../../utils/alerts/showAlert'

interface FirstProps {
  assignment: IAssignment
}

const First = ({ assignment }: FirstProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const isEditingAssignment = useSelector(getIsEditingAssignment)

  const handleDeleteAuxiliary = (assistant: string) => {
    dispatch(deleteAssistantFromAssignment({
      area: assignment.id,
      assistant
    })).unwrap()
      .then(() => showAlert("Auxiliar eliminado", "success"))
      .catch(() => showAlert("Error al eliminar al auxiliar", "error"))
  }

  return (
    <MS.StyledSquare>
      <MS.ItemsWrapper>
        {assignment?.assistants?.length ? (
          assignment.assistants.map((assistant) => (
            <MS.Item key={assistant.id} gap={true}>{isEditingAssignment ? (<button onClick={() => handleDeleteAuxiliary(assistant.id)}><GoDiffRemoved size={25} color="var(--text)" /></button>) : null} {`${assistant.caName} ${assistant.caLastName}`}</MS.Item>
          ))
        ) : null}
      </MS.ItemsWrapper>
    </MS.StyledSquare>
  )
}

export default First