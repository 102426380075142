import styled from "styled-components";

interface PercentageProps {
  width: number
}

export const PersonalWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 40% 60%;
  
  :not(:last-child) {
    margin-bottom: 30px;
  }
`
export const Name = styled.span`
  color: var(--text);
  font-size: 20px;
  font-weight: 500;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`
export const Percentage = styled.div<PercentageProps>`
  background: var(--green);
  border-radius: 0 18px 18px 0;
  height: 35px;
  width: ${({ width }) => `${width}%`};
`