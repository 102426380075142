import React, { useState } from 'react'
import * as SC from './style'
import { getIsEditingAssignment, updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { IAssignment } from '../../../../redux/features/assignments/interfaces'
import { IoIosArrowUp } from 'react-icons/io'
import { MdModeEditOutline } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Button/Button'
import Squares from './Squares/Squares'

interface AssignmentProps {
  assignment: IAssignment
}

const Assignment = ({ assignment }: AssignmentProps) => {
  const dispatch = useDispatch()
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const isEditingAssignment = useSelector(getIsEditingAssignment)

  return (
    <SC.Wrapper>
      <SC.AssignmentWrapper>
        <SC.Area isOpened={isOpened}>{assignment.name} <span>({assignment.pavilions.join(" / ")})</span></SC.Area>
        <SC.ButtonsWrapper>
          {isOpened ? (
            <>
              {
                isEditingAssignment ? (
                  <Button
                    background='var(--light-blue)'
                    color='var(--white)'
                    fontSize='20px'
                    padding='7px 20px'
                    text='Guardar'
                    onClick={() => dispatch(updateState({ name: "isEditingAssignment", value: false }))}
                  />
                ) : (
                  <SC.EditButton onClick={() => dispatch(updateState({ name: "isEditingAssignment", value: true }))}><MdModeEditOutline color='var(--white)' size={30} /></SC.EditButton>
                )
              }
            </>
          ) : null}
          <SC.ArrowButton isOpened={isOpened} onClick={() => setIsOpened(!isOpened)}><IoIosArrowUp color='var(--icon-grey)' size={45} /></SC.ArrowButton>
        </SC.ButtonsWrapper>
      </SC.AssignmentWrapper>
      {isOpened ? <Squares assignment={assignment} /> : null}
    </SC.Wrapper>
  )
}

export default Assignment