import styled from 'styled-components'

export const UnauthorizedWrapper = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 200px;
`
export const Code = styled.p`
  color: red;
  text-align: center;
  margin-top: 20px;
  font-size: 2rem;

`