import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPatient } from "../../redux/features/requestTransfer/interfaces";
import { rest } from "..";
import { urls } from "../urls";

export const getPatient = createAsyncThunk(
  "patient/getPatient",
  async (params: { tipDoc: string, document: string }) => {
    const { tipDoc, document } = params
    const url = `${urls.baseUrl}/patient/${tipDoc}/${document}`
    const { data } = await rest.get<{ data: IPatient[] }>(url)

    if (!data.data.length) throw new Error()

    return data.data[0]
  }
)