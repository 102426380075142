import styled from "styled-components";

interface BarWrapperProps {
  height?: number
}

interface PercentageProps {
  percentage: number
}

export const GraphsWrapper = styled.div`
  border-bottom: 1px solid var(--dark-border);
  margin: 43px 0 110px 0;
`
export const Bars = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 102px;
  height: 432px;
  justify-content: center;
`
export const BarWrapper = styled.div<BarWrapperProps>`
  height: ${({ height = 100 }) => `${height}%`};
  position: relative;

  div {
    height: 100%;
    width: 96px;
    border-radius: 62px 62px 0px 0px;
  }

  :first-of-type {
    div {
      background: var(--second-blue);
    }
  }

  :nth-of-type(2) {
    div {
      background: var(--purple);
    }
  }
  
  :last-of-type {
    div {
      background: var(--fuchsia);
    }
  }
`
export const Percentage = styled.span<PercentageProps>`
  bottom: ${({ percentage = 0 }) => percentage >= 20 ? "23px" : "50px"};
  color: ${({ percentage = 0 }) => percentage >= 20 ? "var(--white)" : "var(--text)"};
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`
export const Total = styled.span`
  bottom: -60px;
  color: var(--text);
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`