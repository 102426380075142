import { createAsyncThunk } from "@reduxjs/toolkit"
import { IAuxiliry, ILoggedAuxiliary } from "../../redux/features/auxiliaries/interfaces"
import { IGetAssistantsStatistics } from "../../redux/features/assistants/interfaces"
import { rest } from ".."
import { RootState } from "../../redux/app/store"
import { urls } from "../urls"

export const getAssistants = createAsyncThunk(
  "assistants/getAssistants",
  async (undefined, { getState }) => {
    const state = getState() as RootState

    if (state.auxiliaries.auxiliries.length) return state.auxiliaries.auxiliries

    const url = `${urls.baseUrl}/assistants/all`
    const { data } = await rest.get<{ data: IAuxiliry[] }>(url)
    return data.data
  }
)
export const getAssistantsStatistics = createAsyncThunk(
  "assistants/getAssistantsStatistics",
  async (queries: { initial_date?: string, final_date?: string }) => {
    const { initial_date = "", final_date = "" } = queries
    const url = `${urls.baseUrl}/assistants/statistics/all?initial_date=${initial_date}&final_date=${final_date}`
    const { data } = await rest.get<{ data: IGetAssistantsStatistics }>(url)
    return data.data
  }
)
export const getAssistantStatisticsById = createAsyncThunk(
  "assistants/getAssistantStatisticsById",
  async (params: { auxiliaryId: string, initial_date?: string, final_date?: string }) => {
    const { auxiliaryId, initial_date = "", final_date = "" } = params

    const url = `${urls.baseUrl}/assistants/statistics/${auxiliaryId}?initial_date=${initial_date}&final_date=${final_date}`
    const { data } = await rest.get(url)
    return data.data
  }
)
export const getLoggedAssistant = createAsyncThunk(
  "assistants/getLoggedAssistant",
  async (document: string = "1001779204") => {
    const url = `${urls.baseUrl}/assistants/mobile/${document}/me`
    const { data } = await rest.get<{ data: ILoggedAuxiliary }>(url)
    return data.data
  }
)