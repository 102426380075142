import { createAsyncThunk } from "@reduxjs/toolkit";
import { IArea, IGetAreas } from "../../redux/features/areas/interfaces";
import { IGetTravelTimes, IPostTravelTime, ITravelTime } from "../../redux/features/travelTime/interfaces";
import { ITransferRequirement } from "../../redux/features/transferRequirements/interfaces";
import { rest } from "..";
import { RootState } from "../../redux/app/store";
import { urls } from "../urls";

enum UrlsEnum {
  requirements = "requirements",
  travelTime = "travels-times",
  areas = "areas",
  assignments = "assignments"
}

export const getTransferRequirements = createAsyncThunk(
  "configuration/getTransferRequirements",
  async (undefined, { getState }) => {
    const state = getState() as RootState

    const value = [
      ...state.transferRequirements.transferRequirements.movilization,
      ...state.transferRequirements.transferRequirements.ventilation
    ]

    if (value.length) return value

    const url = `${urls.baseUrl}/${UrlsEnum.requirements}/all`
    const { data } = await rest.get<{ data: ITransferRequirement[] }>(url)
    return data.data
  }
)
export const postTransferRequirement = createAsyncThunk(
  "configuration/postTransferRequirement",
  async (body: { name: string, type: string }) => {
    const url = `${urls.baseUrl}/${UrlsEnum.requirements}/create`
    const { data } = await rest.post<{ data: ITransferRequirement }>(url, body)
    return { data: data.data, type: body.type }
  }
)
export const deleteTransferRequirement = createAsyncThunk(
  "configuration/deleteTransferRequirement",
  async (params: { requirementId: string, type: string }) => {
    const { requirementId, type } = params
    const url = `${urls.baseUrl}/${UrlsEnum.requirements}/delete/${requirementId}`
    await rest.delete(url)
    return { requirementId, type }
  }
)
export const getTravelTimes = createAsyncThunk(
  "configuration/getTravelTimes",
  async (queries: { page: number, quantity: number, curPage: number }, { getState, rejectWithValue }) => {
    const state = getState() as RootState
    const { page, quantity, curPage } = queries

    if (state.travelTimes.travelTimes.results.length && curPage === page) return state.travelTimes.travelTimes

    const url = `${urls.baseUrl}/${UrlsEnum.travelTime}/all?page=${page || 1}&quantity=${quantity}`

    try {
      const { data } = await rest.get<{ data: IGetTravelTimes }>(url)
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }

  }
)
export const postTravelTime = createAsyncThunk(
  "configuration/postTravelTime",
  async (body: IPostTravelTime) => {
    const url = `${urls.baseUrl}/${UrlsEnum.travelTime}/create`
    const { data } = await rest.post<{ data: ITravelTime }>(url, body)
    return data.data
  }
)
export const putTravelTime = createAsyncThunk(
  "configuration/putTravelTime",
  async (bodyAndParams: { body: IPostTravelTime, travelTimeId: string }) => {
    const { body, travelTimeId } = bodyAndParams
    const url = `${urls.baseUrl}/${UrlsEnum.travelTime}/update/${travelTimeId}`
    await rest.put(url, body)
    return bodyAndParams
  }
)
export const deleteTravelTime = createAsyncThunk(
  "configuration/deleteTravelTime",
  async (travelTimeId: string) => {
    const url = `${urls.baseUrl}/${UrlsEnum.travelTime}/delete/${travelTimeId}`
    await rest.delete(url)
    return travelTimeId
  }
)
export const getAreas = createAsyncThunk(
  "configuration/putTravelTime",
  async (queries: { page: number, quantity: number, curPage: number }, { getState, rejectWithValue }) => {
    const state = getState() as RootState

    const { page, quantity, curPage } = queries
    if (state.areas.areas.results.length && curPage === page) return state.areas.areas

    const url = `${urls.baseUrl}/${UrlsEnum.areas}/all?page=${page || 1}&quantity=${quantity}`

    try {
      const { data } = await rest.get<{ data: IGetAreas }>(url)
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  }
)
export const postArea = createAsyncThunk(
  "configuration/postArea",
  async (undefined, { getState }) => {
    const { id, ...area } = (getState() as RootState).areas.area

    const url = `${urls.baseUrl}/${UrlsEnum.areas}/create`
    const { data } = await rest.post<{ data: IArea }>(url, area)
    return data.data
  }
)
export const putArea = createAsyncThunk(
  "configuration/putArea",
  async (undefined, { getState }) => {
    const { id, ...area } = (getState() as RootState).areas.area

    const url = `${urls.baseUrl}/${UrlsEnum.areas}/update/${id}`
    await rest.put(url, area)
    return { id, ...area }
  })
export const deleteArea = createAsyncThunk(
  "configuration/deleteArea",
  async (areaId: string) => {
    const url = `${urls.baseUrl}/${UrlsEnum.areas}/delete/${areaId}`
    await rest.delete(url)
    return areaId
  }
)
export const getAssignmets = createAsyncThunk(
  "configuration/getAssignmets",
  async (params: { page?: number, quantity?: number, curPage: number }, { getState, rejectWithValue }) => {
    const state = getState() as RootState

    const { page = 1, quantity = 5, curPage } = params
    if (state.assignments.assignments.results.length && curPage === page) return state.assignments.assignments

    const url = `${urls.baseUrl}/${UrlsEnum.assignments}/all?page=${page || 1}&quantity=${quantity}`

    try {
      const { data } = await rest.get(url)
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response.status)
    }
  }
)
export const addAssistantToAssignment = createAsyncThunk(
  "configuration/addAssistantToAssignment",
  async (body: { areaId: string, assistantsId: string }, { getState }) => {
    const { areaId, assistantsId } = body
    const state = getState() as RootState

    const auxiliary = state.auxiliaries.auxiliries.find((aux) => aux.id === assistantsId)
    if (!auxiliary) throw new Error("No se encontró al auxiliar")

    const url = `${urls.baseUrl}/${UrlsEnum.assignments}/update`
    await rest.put(url, body)

    return {
      areaId,
      aux: {
        id: auxiliary?.id,
        caName: auxiliary?.fullname,
        caLastName: ""
      }
    }
  }
)
export const deleteAssistantFromAssignment = createAsyncThunk(
  "configuration/deleteAssistantFromAssignment",
  async (params: { area: string, assistant: string }) => {
    const { area, assistant } = params
    const url = `${urls.baseUrl}/${UrlsEnum.assignments}/delete/${area}/${assistant}`
    await rest.delete(url)
    return { area, assistant }
  }
)