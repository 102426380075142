import React from 'react'
import { ADMIN_TRANSFERS } from '../../../../routes'
import { getTransferState } from '../../../../redux/features/transfers/transferSlice'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Message from '../../../../components/Message/Message'
import Table from '../../../../components/Table/Table'

const TransfersTable = () => {
  const transfers = useSelector(getTransferState)

  return (
    <Table gridColumns='15% 25% 15% 15% 15% 15%'>
      <div className="titles">
        <span>Radicado</span>
        <span>Paciente</span>
        <span>Habitación</span>
        <span>Estado</span>
        <span>Tip. Traslado</span>
        <span>Fecha</span>
      </div>
      <div className="rows">
        {transfers.results.length ? (
          <>
            {transfers.results.map((transfer) => (
              <Link key={transfer.id} to={`${ADMIN_TRANSFERS}/${transfer.id}`} className="row">
                <span>{transfer.consecutive || "-"}</span>
                <span>{transfer.patient || "-"}</span>
                <span>{transfer.hab || "-"}</span>
                <span>{transfer.status || "-"}</span>
                <span>{transfer.type_transfer || "-"}</span>
                <span>{new Date(transfer.createdAt).toLocaleDateString()}</span>
              </Link>
            ))}
          </>
        ) : <Message>No hay datos...</Message>}

      </div>
    </Table>
  )
}

export default TransfersTable