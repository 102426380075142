import { AUX_PENDINGS } from '../../../routes'
import { getAuxiliary } from '../../../redux/features/auxiliaries/auxiliarieSlice'
import { getPendingTransfers } from '../../../redux/features/transfers/transferSlice'
import { getTransfersByArea } from '../../../services/transfers'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Message from '../../../components/Message/Message'
import Pagination from '../../../components/Pagination/Pagination'
import Table from '../../../components/Table/Table'

const PendingsTable = () => {
  const dispatch = useDispatch()
  const auxiliary = useSelector(getAuxiliary)
  const pendingTransfers = useSelector(getPendingTransfers)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!auxiliary.document) return
    dispatch(getTransfersByArea({ page }))
  }, [page, auxiliary])

  return (
    <>
      <Table gridColumns='15% 25% 15% 15% 15% 15%'>
        <div className="titles">
          <span>Radicado</span>
          <span>Paciente</span>
          <span>Habitación</span>
          <span>Estado</span>
          <span>Tip. Traslado</span>
          <span>Hora Traslado</span>
        </div>
        <div className="rows">
          {pendingTransfers?.results?.length ? (
            pendingTransfers.results.map((item) => (
              <Link key={item.id} to={`${AUX_PENDINGS}/${item.id}`} className="row">
                <span>#100514</span>
                <span>Juan Camilo López Cueto</span>
                <span>LIR404</span>
                <span>Pendiente</span>
                <span>sencillo</span>
                <span>9:30 AM</span>
              </Link>
            ))
          ) : <Message>No hay resultados...</Message>}
        </div>
      </Table>
      <Pagination pageCount={pendingTransfers.totalPages} page={page} marginTop="50px" onPageChange={({ selected }) => setPage(selected + 1)} />
    </>
  )
}

export default PendingsTable