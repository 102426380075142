import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userHasPermissions } from "../../../services/permissions";
import { RootState } from "../../app/store";

interface IPermissionState {
  permissions: {
    "admin": boolean,
    "access": boolean,
    "requestor": boolean
    "auxiliary": boolean,
    "administrator": boolean
  },
  isLoading: boolean
}

const initialState: IPermissionState = {
  permissions: {
    "admin": false,
    "access": false,
    "requestor": false,
    "auxiliary": false,
    "administrator": false
  },
  isLoading: false
}

const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userHasPermissions.pending, state => {
        state.isLoading = true
      })
      .addCase(userHasPermissions.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.permissions["admin"] = payload["auxclinic-admin"]
        state.permissions["access"] = payload["auxclinic-access"]
        state.permissions["administrator"] = payload["auxclinic-administrator"]
        state.permissions["requestor"] = payload["auxclinic-requestor"]
        state.permissions["auxiliary"] = payload["auxclinic-auxiliary"]
        state.isLoading = false
      })
      .addCase(userHasPermissions.rejected, state => {
        state.permissions["admin"] = false
        state.permissions["access"] = false
        state.permissions["administrator"] = false
        state.permissions["requestor"] = false
        state.permissions["auxiliary"] = false
        state.isLoading = false
      })
  }
})

export const getPermissions = (store: RootState) => store.permissions.permissions

export const { } = permissionSlice.actions
export default permissionSlice.reducer