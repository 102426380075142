import React, { FormEvent, useEffect, useState } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../../redux/app/store'
import { getCurrentArea, resetArea } from '../../../../redux/features/areas/areaSlice'
import { getPavilions } from '../../../../services/pavilions'
import { IoClose } from 'react-icons/io5'
import { postArea } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Button/Button'
import Form from '../Form/Form'
import Modal from '../../../../components/Modal/Modal'
import Pavilions from '../Pavilions/Pavilions'

const AddArea = () => {
  const dispatch = useDispatch<AppDispatch>()
  const area = useSelector(getCurrentArea)

  useEffect(() => {
    dispatch(getPavilions())

    return (() => {
      dispatch(resetArea())
    })
  }, [])

  const handleSubmitArea = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!area.pavilions.length) return showAlert("No se agregó ningún pabellón", "info")


    dispatch(postArea()).unwrap()
      .then(() => {
        showAlert("Area creada", "success")
        dispatch(updateState({ name: "addAreaModal", value: false }))
        dispatch(resetArea())
      }).catch(() => showAlert("Error al crear el area", "error"))
  }

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "addAreaModal", value: false }))}>
      <SC.ContentWrapper onSubmit={handleSubmitArea}>
        <Form />
        <Pavilions pavilions={area.pavilions} />
        <Button
          text='Crear'
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          marginTop="128px"
        />
        <button className="close-btn modal-wrapper"><IoClose size={35} className="modal-wrapper" /></button>
      </SC.ContentWrapper>
    </Modal>
  )
}

export default AddArea