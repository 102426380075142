import React from 'react'
import * as SC from './style'
import { IAssignment } from '../../../../../redux/features/assignments/interfaces'
import First from './First/First'
import Second from './Second/Second'

interface SquaresProps {
  assignment: IAssignment
}

const Squares = ({ assignment }: SquaresProps) => {
  return (
    <SC.SquaresWrapper>
      <First assignment={assignment} />
      <Second assignment={assignment} />
    </SC.SquaresWrapper>
  )
}

export default Squares