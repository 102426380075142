import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { AppDispatch } from '../../../redux/app/store'
import { getAddAreaModal, getEditAreaModal, updateState } from '../../../redux/features/transferRequirements/transferRequirementsSlice'
import { getAreas } from '../../../services/configuration'
import { getAreaState, getIsLoading } from '../../../redux/features/areas/areaSlice'
import { showAlert } from '../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import AddArea from './AddArea/AddArea'
import ConfigTable from '../../../components/ConfigTable/ConfigTable'
import EditArea from './EditArea/EditArea'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Pagination from '../../../components/Pagination/Pagination'
import Row from './Row/Row'
import Message from '../../../components/Message/Message'

const AreaTable = () => {
  const dispatch = useDispatch<AppDispatch>()
  const addAreaModal = useSelector(getAddAreaModal)
  const areas = useSelector(getAreaState)
  const editAreaModal = useSelector(getEditAreaModal)
  const isLoading = useSelector(getIsLoading)
  const [page, setPage] = useState<number>(Number(localStorage.getItem("area-page")) || 0)
  const [curPage, setcurPage] = useState<number>(Number(localStorage.getItem("area-cur-page")) || 0)

  useEffect(() => {
    dispatch(getAreas({ page, quantity: 6, curPage })).unwrap()
      .catch((code) => {
        if (code === 404) return showAlert("No hay resultados", "info")
        showAlert("Error al traer la información", "error")
      })
    setcurPage(page)
    localStorage.setItem("area-cur-page", String(page))
  }, [page])

  const handlePageChange = ({ selected }: any) => {
    setcurPage(page)
    localStorage.setItem("area-cur-page", String(page))

    const value = selected + 1
    localStorage.setItem("area-page", value)

    setPage(value)
  }

  return (
    <>
      <ConfigTable>
        <div className="titles">
          <span>Areas</span>
          <span>Pabellones / Servicios</span>
          <button onClick={() => dispatch(updateState({ name: "addAreaModal", value: true }))}><AiOutlinePlus color='var(--white)' size={30} /></button>
        </div>
        <div className="rows">
          {
            areas?.results?.length ? (
              areas.results.map((area) => (
                <Row key={area.id} area={area} />
              ))
            ) : (
              <Message>No hay resultados...</Message>
            )
          }

        </div>
      </ConfigTable>
      {addAreaModal ? (<AddArea />) : null}
      {editAreaModal ? (<EditArea />) : null}
      {isLoading ? <MainLoading /> : null}
      <Pagination pageCount={areas.totalPages} page={page} marginTop="50px" onPageChange={handlePageChange} />
    </>
  )
}

export default AreaTable