import { createAsyncThunk } from "@reduxjs/toolkit";
import { rest } from "..";
import { urls } from "../urls";

export const getLoggedUser = createAsyncThunk(
  "users/getLoggedUser",
  async () => {
    const url = `${urls.usersBaseUrl}/users/me`
    const { data } = await rest.get(url)
    return data
  }
)