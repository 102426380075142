import React from 'react'
import { FormGroup, CustomGroups } from '../../../../../GlobalStyles'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'
import { useSelector } from 'react-redux'

const Form = () => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={transfer.patient} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={transfer.type_document} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={transfer.document} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={transfer.hab} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={transfer.type_transfer} />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={transfer.origin} />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={transfer.destination} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={transfer.hour} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Solicitante</label>
          <input type="text" value={transfer.applicant} />
        </FormGroup>
      </CustomGroups>
    </>
  )
}

export default Form