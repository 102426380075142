import React, { useEffect } from 'react'
import { ADMIN_TRANSFERS } from '../../../../routes'
import { AppDispatch } from '../../../../redux/app/store'
import { getIsLoading, getTransfer, resetTransfer } from '../../../../redux/features/transfers/transferSlice'
import { getTransferById } from '../../../../services/transfers'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Back from '../../../../components/Back/Back'
import Simple from './Simple/Simple'
import T990 from './T990/T990'
import MainLoading from '../../../../components/MainLoading/MainLoading'
import { getPermissions } from '../../../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../../../components/Unauthorized/Unauthorized'

const TransfersDetails = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { access, admin, administrator } = useSelector(getPermissions)
  const isLoading = useSelector(getIsLoading)
  const transfer = useSelector(getTransfer)
  const { transferId } = useParams<{ transferId: string }>()

  useEffect(() => {
    dispatch(getTransferById(transferId!))

    return (() => {
      dispatch(resetTransfer())
    })
  }, [])

  return (
    <>
      {
        administrator || access || admin ? (
          <>
            <Back url={ADMIN_TRANSFERS} />
            {transfer.type_transfer === "Sencillo" ? <Simple /> : null}
            {transfer.type_transfer === "Redondo" ? <Simple /> : null}
            {transfer.type_transfer === "990 Salida" ? <Simple /> : null}
            {transfer.type_transfer === "990" ? <T990 /> : null}
          </>
        ) : (
          <Unauthorized />
        )
      }

      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default TransfersDetails