import React from 'react'
import * as SC from './style'

interface TableProps {
  gridColumns?: string;
  height?: string;
  children: JSX.Element | JSX.Element[];
}

const Table = ({ gridColumns, height, children }: TableProps) => {
  return (
    <SC.TableWrapper
      height={height}
      gridColumns={gridColumns}
    >{children}</SC.TableWrapper>
  )
}

export default Table