import { Link } from "react-router-dom";
import styled from "styled-components";
// import Bg from '../../assets/images/main-bg.png'

export const MainPageWrapper = styled.div`
  background: var(--white);
  align-items: center;
  background-image: url(${process.env.PUBLIC_URL + "/main-bg.png"});
  background-size: cover;
  display: flex;
  height: 96vh;
  justify-content: center;
  left: 0;
  position: fixed;  
  top: 4vh;
  width: 100%;
  outline: 1px solid red;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 650px;
  }
`
export const MainText = styled.span`
  color: var(--text);
  display: inline-block;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 65px;
  text-align: center;
  width: 550px;
`
export const CustomLink = styled(Link)`
  background-color: var(--sea-blue);
  padding: 7px 6px 0 9px;
  border-radius: 50%;
  margin-top: 55px;
`