import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAssistantsStatistics, getAssistantStatisticsById } from "../../../services/assistants";
import { IGetAssistantsStatistics } from "./interfaces";
import { RootState } from "../../app/store";

interface IAssistantState {
  isLoading: boolean,
  assistansStatistics: IGetAssistantsStatistics,
  assistanStatistics: any
}

const initialState: IAssistantState = {
  isLoading: false,
  assistansStatistics: {
    statistics: [],
    totalTranfers: 0
  },
  assistanStatistics: {}
}

const assistantsSlice = createSlice({
  name: "assistants",
  initialState,
  reducers: {
    resetAssistantStatistics: (state) => {
      state.assistanStatistics = initialState.assistanStatistics
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssistantsStatistics.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssistantsStatistics.fulfilled, (state, { payload }: PayloadAction<IGetAssistantsStatistics>) => {
        state.assistansStatistics = payload
        state.isLoading = false
      })
      .addCase(getAssistantsStatistics.rejected, (state) => {
        state.assistansStatistics = initialState.assistansStatistics
        state.isLoading = false
      })
    builder
      .addCase(getAssistantStatisticsById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssistantStatisticsById.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.assistanStatistics = payload
        state.isLoading = false
      })
      .addCase(getAssistantStatisticsById.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getAssistansStatistics = (store: RootState) => store.assistants.assistansStatistics
export const getAssistantStatisctics = (store: RootState) => store.assistants.assistanStatistics
export const getIsLoading = (store: RootState) => store.assistants.isLoading

export const { resetAssistantStatistics } = assistantsSlice.actions
export default assistantsSlice.reducer