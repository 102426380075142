import React, { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ITravelTime } from '../../../../redux/features/travelTime/interfaces'
import Action from './Action/Action'

interface RowProps {
  travelTime: ITravelTime
}

const Row = ({ travelTime }: RowProps) => {
  const [showActions, setShowActions] = useState(false)

  return (
    <div className="row">
      <span>{travelTime.origin}</span>
      <span>{travelTime.destination}</span>
      <span>{travelTime.type}</span>
      <span>{travelTime.time}min</span>
      <button className='dots' onClick={() => setShowActions(!showActions)}><BiDotsVerticalRounded color='var(--third-grey)' size={40} /></button>
      {showActions ? <Action travelTimeId={travelTime.id} setShowActions={setShowActions} /> : null}
    </div>
  )
}

export default Row