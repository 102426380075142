import styled from "styled-components";

export const AsignTransferWrapper = styled.form`
  background: var(--modal-grey);
  border-radius: 15px;
  margin: 210px auto 0 auto;
  padding: 94px 0 54px 0;
  position: relative;
  width: 904px;

  button {
    margin: 0 auto;
  }

  button.close {
    background: none;
    border: 1px solid red;
    border: none;
    color: var(--text);
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 20px;

    svg {
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1440px) {
    margin: 100px auto 0 auto;
  }
`
export const Groups = styled.div`
  align-items: center;
  display: flex;
  gap: 37px;
  justify-content: center;
  margin-bottom: 70px;
`
export const Group = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: var(--text);
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;

    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
  }

  input {
    background: var(--white);
    border-radius: 8px;
    border: none;
    color: var(--text);
    font-size: 28px;
    font-weight: 400;
    padding: 15px;
    text-align: center;
    width: 323px;

    @media screen and (max-width: 1440px) {
      font-size: 23px;
    }
  }

  div {
    align-items: center;
    background: var(--white);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 323px;
    
    select {
      appearance: none;
      background: none;
      border: none;
      color: var(--text);
      cursor: pointer;
      font-size: 28px;
      font-weight: 400;
      padding: 15px;
      text-align: center;
      width: 100%;

      @media screen and (max-width: 1440px) {
        font-size: 23px;
      }
    }

    svg {
      pointer-events: none;
      position: absolute;
      right: 20px;
    }
  }

`