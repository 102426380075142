import React from 'react'
import * as MS from '../../../style'
import { getTransferRequirementsState } from '../../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useSelector } from 'react-redux'
import Item from './Item/Item'

const List = () => {
  const transferRequirementsState = useSelector(getTransferRequirementsState)

  return (
    <MS.ListWrapper>
      {!transferRequirementsState.ventilation.length ? (
        <MS.NoDataMeesage>Nada que mostrar...</MS.NoDataMeesage>
      ) :
        transferRequirementsState.ventilation.map((tr) => <Item key={tr.id} transferRequirement={tr} />)
      }
    </MS.ListWrapper>
  )
}

export default List