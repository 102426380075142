import styled from "styled-components";

export const PavilionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 23px;
  max-height: 200px;
  overflow: auto;
`
export const Pavilions = styled.div`
  background: var(--pavilion-grey);
  padding: 5px 20px 5px 39px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  
  span {
    color: var(--white);
    font-size: 22px;
    font-weight: 300;
  }

  button {
    background: none;
    border: none;
    margin-left: 20px;
    margin-top: 5px;
  }
`