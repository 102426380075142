import React from 'react'
import * as SC from './style'
import { FaPlay } from 'react-icons/fa'

interface BackProps {
  url: string
}

const Back = ({ url }: BackProps) => {
  return (
    <>
      <SC.BackButton to={url}><FaPlay color='var(--light-grey)' size={25} /> Atrás</SC.BackButton>
    </>
  )
}

export default Back