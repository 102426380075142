import React from 'react'
import { FileNumber, FormGroup, FormWrapper } from '../../../../GlobalStyles'
import { getTransfer } from '../../../../redux/features/transfers/transferSlice'
import { useSelector } from 'react-redux'
import Form from './Form/Form'
import Requirements from '../../../../components/Requirements/Requirements'

const Simple = () => {
  const transfer = useSelector(getTransfer)

  return (
    <FormWrapper>
      <FileNumber margin='0 0 18px 0'>{transfer?.consecutive}</FileNumber>
      <Form />
      <Requirements requirements={transfer?.requeriments} />
      <FormGroup totalWidth="calc(100% - 72px)" margin='24px 0 86px 0'>
        <label htmlFor="">Descripción</label>
        <textarea value={transfer?.description} readOnly></textarea>
      </FormGroup>
    </FormWrapper>
  )
}

export default Simple