import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAssistants, getLoggedAssistant } from "../../../services/assistants";
import { IAuxiliry, ILoggedAuxiliary } from "./interfaces";
import { RootState } from "../../app/store";

interface AuxiliarieState {
  isLoading: boolean,
  auxiliries: IAuxiliry[]
  auxiliary: ILoggedAuxiliary;
}

const initialState: AuxiliarieState = {
  isLoading: false,
  auxiliries: [],
  auxiliary: {
    area: "",
    document: "",
    id: "",
    imc: "",
    name: "",
    pavilion: "",
    status: "",
    transfer_per_area: 0,
    transfers: 0,
  }
}

const auxiliarieSlice = createSlice({
  name: "auxiliaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssistants.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssistants.fulfilled, (state, { payload }: PayloadAction<IAuxiliry[]>) => {
        state.auxiliries = payload
        state.isLoading = false
      })
      .addCase(getAssistants.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(getLoggedAssistant.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLoggedAssistant.fulfilled, (state, { payload }: PayloadAction<ILoggedAuxiliary>) => {
        state.auxiliary = payload
        state.isLoading = false
      })
      .addCase(getLoggedAssistant.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.auxiliaries.isLoading
export const getAuxiliaries = (store: RootState) => store.auxiliaries.auxiliries
export const getAuxiliary = (store: RootState) => store.auxiliaries.auxiliary

export const { } = auxiliarieSlice.actions
export default auxiliarieSlice.reducer