import React, { FormEvent } from "react";
import { AppDispatch } from "../../../../redux/app/store";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { FileNumber, FormGroup, FormWrapper } from "../../../../GlobalStyles";
import {
  getIsLoading,
  getTransfer,
} from "../../../../redux/features/transfers/transferSlice";
import { getTransferRequirementsState } from "../../../../redux/features/transferRequirements/transferRequirementsSlice";
import { getUser } from "../../../../redux/features/users/userSlice";
import { postReturnTransfer } from "../../../../services/transfers";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import Form from "./Form/Form";
import Requirements from "../../../../components/Requirements/Requirements";
import { showAlert } from "../../../../utils/alerts/showAlert";
import MainLoading from "../../../../components/MainLoading/MainLoading";
import { useNavigate } from "react-router-dom";
import { REQUESTOR } from "../../../../routes";

const Round = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const transfer = useSelector(getTransfer);
  const isLoading = useSelector(getIsLoading);
  const user = useSelector(getUser);
  const requirements = useSelector(getTransferRequirementsState);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let mobilization_request: string = "";
    let transfer_request: string = "";
    const transformedRequirements = [
      ...requirements.movilization,
      ...requirements.ventilation,
    ].map((item) => item.name);

    transfer.requeriments.forEach((item) => {
      if (transformedRequirements.includes(item)) {
        mobilization_request = item;
      } else {
        transfer_request = item;
      }
    });

    dispatch(
      postReturnTransfer({
        patient: transfer.patient,
        type_document: transfer.type_document,
        document: transfer.document,
        hab: transfer.hab,
        hour: transfer.hour,
        description: transfer.description,
        destination: transfer.origin,
        origin: transfer.destination,
        returnee: true,
        type_transfer: transfer.type_transfer,
        mobilization_request: transfer.requeriments[0],
        transfer_request: transfer.requeriments[1],
        applicant: user.document,
      })
    )
      .unwrap()
      .then(() => {
        showAlert("Traslado retornado", "success");
        navigate(REQUESTOR);
      })
      .catch(() => showAlert("Aun no se ha terminado el traslado", "error"));
  };

  return (
    <>
      <FormWrapper className="add-margin-bottom" onSubmit={handleSubmit}>
        <FileNumber>{transfer.consecutive}</FileNumber>
        <Form />
        <Requirements requirements={transfer?.requeriments} />
        <FormGroup totalWidth="calc(100% - 72px)" margin="24px 0 56px 0">
          <label htmlFor="">Descripción</label>
          <textarea value={transfer?.description} readOnly></textarea>
        </FormGroup>
        <Button
          text="Retornar"
          boldText={true}
          background="var(--green)"
          color="var(--white)"
          center={true}
          marginBottom="33px"
          svgMargin="4px 0 0 11px"
        >
          <BsArrowCounterclockwise size={32} />
        </Button>
      </FormWrapper>
      {isLoading ? <MainLoading /> : null}
    </>
  );
};

export default Round;
