import { CustomGroups, FormGroup, Groups } from '../../../../../GlobalStyles'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'
import { useSelector } from 'react-redux'

const Form = () => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={transfer.patient} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={transfer.type_document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={transfer.document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={transfer.hab} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={transfer.type_transfer} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={transfer.origin} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={transfer.destination} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={transfer.hour} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Solicitante</label>
          <input type="text" value={transfer.applicant} disabled />
        </FormGroup>
      </CustomGroups>
    </>
  )
}

export default Form