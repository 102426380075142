import React, { useEffect, useState } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../redux/app/store'
import { getAssignmets } from '../../../services/configuration'
import { getAssignmetsState, getIsLoading } from '../../../redux/features/assignments/assignmentSlice'
import { showAlert } from '../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import Assignment from './Assignment/Assignment'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Pagination from '../../../components/Pagination/Pagination'
import Message from '../../../components/Message/Message'

const Assignments = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)
  const assignments = useSelector(getAssignmetsState)
  const [page, setPage] = useState<number>(Number(localStorage.getItem("assignment-page")) || 0)
  const [curPage, setcurPage] = useState<number>(Number(localStorage.getItem("assignment-cur-page")) || 0)

  useEffect(() => {
    dispatch(getAssignmets({ page, curPage })).unwrap()
      .catch((code) => {
        if (code === 404) return showAlert("No hay resultados", "info")
        showAlert("Error al traer la información", "error")
      })
    setcurPage(page)
    localStorage.setItem("assignment-cur-page", String(page))
  }, [page])

  const handlePageChange = ({ selected }: any) => {
    setcurPage(page)
    localStorage.setItem("assignment-cur-page", String(page))

    const value = selected + 1
    localStorage.setItem("assignment-page", value)

    setPage(value)
  }

  return (
    <>
      <SC.AssignmentsWrapper>
        {assignments?.results?.length ? (
          assignments.results.map((assignment) => (
            <Assignment key={assignment.id} assignment={assignment} />
          ))
        ) : <Message>No hay resultados...</Message>}
      </SC.AssignmentsWrapper>
      <Pagination pageCount={assignments.totalPages} page={page} marginTop="50px" onPageChange={handlePageChange} />
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Assignments