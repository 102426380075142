import Swal from "sweetalert2"

export const showConfirmAlert = async (options: {
  title: string,
  text: string,
  icon: "error" | "success" | "info" | "warning" | "question"
}) => {
  const { isConfirmed } = await Swal.fire({
    title: options.title,
    text: options.text,
    icon: options.icon,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: "Si",
    cancelButtonText: "No",
    confirmButtonColor: "var(--light-blue)",
    cancelButtonColor: "var(--red)",
    // toast: true
  })

  return isConfirmed
}