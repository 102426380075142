import React, { useEffect, useState } from 'react'
import { AUX_HISTORY } from '../../../routes'
import { getAssistantHistoricalTransfers } from '../../../services/transfers'
import { getHistoricalTransfers, getIsLoading } from '../../../redux/features/transfers/transferSlice'
import { getUser } from '../../../redux/features/users/userSlice'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Message from '../../../components/Message/Message'
import Pagination from '../../../components/Pagination/Pagination'
import Table from '../../../components/Table/Table'

const HistoryTable = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const historicalTransfers = useSelector(getHistoricalTransfers)
  const isLoading = useSelector(getIsLoading)
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getAssistantHistoricalTransfers({
      document: user.document,
      page,
      quantity: 8
    }))
  }, [page])

  return (
    <>
      <Table gridColumns='15% 25% 15% 15% 15% 15%'>
        <div className="titles">
          <span>Radicado</span>
          <span>Paciente</span>
          <span>Habitación</span>
          <span>Estado</span>
          <span>Tip. Traslado</span>
          <span>Hora Traslado</span>
        </div>
        <div className="rows">
          {
            historicalTransfers.results.length ? (
              historicalTransfers.results.map((item) => (
                <Link key={item.id} to={`${AUX_HISTORY}/${item.id}`} className="row">
                  <span>{item.consecutive}</span>
                  <span>{item.patient}</span>
                  <span>{item.hab}</span>
                  <span>{item.status}</span>
                  <span>{item.type_transfer}</span>
                  <span>{item.hour}</span>
                </Link>
              ))
            ) : <Message>No hay resultados...</Message>
          }
        </div>
      </Table>
      <Pagination pageCount={historicalTransfers.totalPages} page={page} marginTop="50px" onPageChange={({ selected }) => setPage(selected + 1)} />
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default HistoryTable