import React from 'react'
import * as SC from './style'
import { ADMIN_AUXILIARES, ADMIN_DASHBOARD, ADMIN_TRANSFERS, AUXILIARES, CONFIGURATION, REQUESTOR } from '../../routes'
import { useSelector } from 'react-redux'
import { getPermissions } from '../../redux/features/permissions/permissionSlice'

const Sidebar = () => {
  const { access, admin, administrator, auxiliary, requestor } = useSelector(getPermissions)

  return (
    <SC.SidebarWrapper>
      {
        auxiliary || access || admin ? (
          <>
            <SC.Section>Aux --------------</SC.Section>
            <div>
              <SC.CustomNavlink to={AUXILIARES} margin="6px 0"><span>Dashboard</span></SC.CustomNavlink>
            </div>
          </>
        ) : null
      }
      {
        requestor || access || admin ? (
          <>
            <SC.Section>Solic --------------</SC.Section>
            <div>
              <SC.CustomNavlink to={REQUESTOR} margin="6px 0"><span>Dashboard</span></SC.CustomNavlink>
            </div>
          </>
        ) : null
      }
      {
        administrator || access || admin ? (
          <>
            <SC.Section>Admin --------------</SC.Section>
            <div>
              <SC.CustomNavlink to={ADMIN_DASHBOARD} margin="6px 0 4px 0"><span>Dashboard</span></SC.CustomNavlink>
              <SC.CustomNavlink to={ADMIN_AUXILIARES}><span>Auxiliares</span></SC.CustomNavlink>
              <SC.CustomNavlink to={ADMIN_TRANSFERS}><span>Traslados</span></SC.CustomNavlink>
            </div>
          </>
        ) : null
      }
      {
        administrator || access || admin ? (
          <SC.ConfigLink to={CONFIGURATION} className="config"><span>Configuración</span></SC.ConfigLink>
        ) : null
      }

      <SC.WaterMark>
        <span>v.1.0.13</span>
      </SC.WaterMark>
    </SC.SidebarWrapper>
  )
}

export default Sidebar