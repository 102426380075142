import React from 'react'
import * as SC from './style'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { showConfirmAlert } from '../../../../utils/alerts/confirmAlert'
import { updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../redux/app/store'
import { deleteArea } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'

interface ActionsProps {
  areaId: string,
  setShowActions: (showActions: false) => void
}

const Actions = ({ areaId, setShowActions }: ActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleEditClick = () => {
    localStorage.setItem("areaId", areaId)
    setShowActions(false)
    dispatch(updateState({ name: "editAreaModal", value: true }))
  }

  const handleDeleteClick = async () => {
    setShowActions(false)
    const isConfirmed = await showConfirmAlert({
      title: "Estás seguro/a?",
      text: "Esta acción es irreversible",
      icon: "question"
    })

    if (!isConfirmed) return

    dispatch(deleteArea(areaId)).unwrap()
      .then(() => showAlert("Area eliminada", "success"))
      .catch(() => showAlert("Error al eliminar el area", "error"))
  }

  return (
    <SC.ActionsWrapper>
      <SC.Btn onClick={handleEditClick}>Editar <FiEdit size={35} /></SC.Btn>
      <SC.Btn onClick={handleDeleteClick}>Eliminar <FiTrash size={35} /></SC.Btn>
    </SC.ActionsWrapper>
  )
}

export default Actions