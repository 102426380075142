import styled from "styled-components"
import { Groups } from "../../../../GlobalStyles"

export const CustomGroups = styled(Groups)`
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 39px 52px;
`
export const CustomGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: var(--text);
    font-size: 20px;
    margin-bottom: 10px;
  }

  input {
    border-radius: 3px;
    border: none;
    border-radius: 6px;
    padding: 11px;
    font-size: 21px;
    text-align: center;
    color: var(--text);
  }
`