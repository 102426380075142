import styled from "styled-components";

export const HeaderWrapper = styled.header`
  background: var(--white);
  height: 4vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  box-shadow: 0px 3px 6px #00000026;
  text-align: center;
`