import styled from "styled-components";

export const ContentWrapper = styled.div`
  background: var(--white);
  border-radius: 18px;
  box-shadow: 0px 3px 6px var(--shadow);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 48px 0 82px 0;
  padding: 46px 68px;
`