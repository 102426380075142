import React, { ChangeEvent, FormEvent, useEffect } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../../redux/app/store'
import { FileNumber, FormGroup, CustomGroups, Line } from '../../../../GlobalStyles'
import { getIsLoading, getTransfer, updateTransfer } from '../../../../redux/features/transfers/transferSlice'
import { getTransferById, post990Transfer } from '../../../../services/transfers'
import { REQUESTOR, REQ_TRANSFER } from '../../../../routes'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Back from '../../../../components/Back/Back'
import Button from '../../../../components/Button/Button'
import Form from './Form/Form'
import MainLoading from '../../../../components/MainLoading/MainLoading'

const Request990 = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)
  const transfer = useSelector(getTransfer)
  const navigate = useNavigate()
  const { transferId } = useParams<{ transferId: string }>()

  useEffect(() => {
    dispatch(getTransferById(transferId!))
  }, [])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(post990Transfer(transferId!)).unwrap()
      .then(() => {
        showAlert("Transferencia creada", "success")
        navigate(`${REQ_TRANSFER}/${transferId}`)
      })
      .catch(() => showAlert("Error al crear la transferencia", "error"))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target

    dispatch(updateTransfer({ name, value }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Back url={REQUESTOR} />
        <SC.Request990Wrapper>
          <FileNumber>{transfer.consecutive}</FileNumber>
          <Form handleChange={handleChange} />
          <Line />
          <CustomGroups>
            <FormGroup big={true}>
              <label htmlFor="">Nombre acompañante</label>
              <input type="text" name='companion' onChange={handleChange} required />
            </FormGroup>
            <FormGroup big={true}>
              <label htmlFor="">Telefono acompañante</label>
              <input type="tel" name='companion_phone' onChange={handleChange} required />
            </FormGroup>
          </CustomGroups>
        </SC.Request990Wrapper>
        <Button
          text='Finalizar'
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          fontSize="20px"
          boldText={true}
          marginTop="63px"
        />
      </form>
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Request990