import Admin from '../pages/Admin/Admin'
import AuxiliaresStatus from '../pages/Admin/AuxiliaresStatus/AuxiliaresStatus'
import Auxiliaries from '../pages/Auxiliaries/Auxiliaries'
import Configuration from '../pages/Configuration/Configuration'
import Details from '../pages/Admin/Dashboard/PersonalList/Details/Details'
import HistoryRequest from '../pages/Auxiliaries/HistoryTable/Request/Request'
import Request from '../pages/Auxiliaries/PendingsTable/Request/Request'
import RequestedTransfer from '../pages/Requestor/RequestedTransfer/RequestedTransfer'
import Requestor from '../pages/Requestor/Requestor'
import Transfers from '../pages/Admin/Transfers/Transfers'
import TransfersDetails from '../pages/Admin/Transfers/TransfersDetails/TransfersDetails'
import Request990 from '../pages/Requestor/AsignTransfer/Request990/Request990'
import MainPage from '../pages/MainPage/MainPage'

export const MAIN = "/"
export const ADMIN = "/admin"
export const AUXILIARES = "/auxiliares"
export const CONFIGURATION = '/configuracion'
export const HISTORY = "/historico"
export const REQUESTOR = "/solicitante"

// AUXILIARES
export const AUX_HISTORY = `${AUXILIARES}/historico`
export const AUX_PENDINGS = `${AUXILIARES}/pendientes`

// SOLICITANTE
export const REQ_TRANSFER = `${REQUESTOR}/traslado`

// ADMIN
export const ADMIN_AUXILIARES = `${ADMIN}/auxiliares`
export const ADMIN_DASHBOARD = `${ADMIN}/dashboard`
export const ADMIN_TRANSFERS = `${ADMIN}/traslados`

export const routes = [
  {
    path: MAIN,
    element: <MainPage />
  },
  {
    path: AUXILIARES,
    element: <Auxiliaries />
  },
  {
    path: `${AUX_PENDINGS}/:requestId`,
    element: <Request />
  },
  {
    path: `${AUX_HISTORY}/:requestId`,
    element: <HistoryRequest />
  },
  {
    path: REQUESTOR,
    element: <Requestor />
  },
  {
    path: `${REQ_TRANSFER}/:transferId`,
    element: <RequestedTransfer />
  },
  {
    path: `${REQUESTOR}/990/:transferId`,
    element: <Request990 />
  },
  {
    path: `${REQUESTOR}/:transferType`,
    element: <RequestedTransfer />
  },
  {
    path: ADMIN_DASHBOARD,
    element: <Admin />
  },
  {
    path: `${ADMIN_DASHBOARD}/:auxiliary/:auxiliaryId`,
    element: <Details />
  },
  {
    path: ADMIN_AUXILIARES,
    element: <AuxiliaresStatus />
  },
  {
    path: ADMIN_TRANSFERS,
    element: <Transfers />
  },
  {
    path: `${ADMIN_TRANSFERS}/:transferId`,
    element: <TransfersDetails />
  },
  {
    path: CONFIGURATION,
    element: <Configuration />
  }
]