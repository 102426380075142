import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

interface CustomNavlinkProps {
  margin?: string
}

export const SidebarWrapper = styled.div`
  background: var(--blue);
  background: linear-gradient(180deg, var(--blue) 0%, var(--dark-blue) 100%);
  color: var(--white);
  height: 96vh;
  left: 0;
  padding: 35px 0px 0 0;
  position: fixed;
  top: 4vh;
  width: 278px;

  @media screen and (max-width: 1440px) {
    width: 200px;
  }
`
export const Section = styled.div`
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
`
export const CustomNavlink = styled(NavLink) <CustomNavlinkProps>`
  color: var(--white);
  display: inline-block;
  font-size: 25px;
  font-weight: 400;
  margin: ${({ margin = "0" }) => margin};
  position: relative;
  text-decoration: none;
  width: 100%;

  .config {
    position: fixed;
    bottom: 10px;
  }

  :nth-child(2) {
    span {
      border-bottom: 1px solid var(--white);
      border-top: 1px solid var(--white);
    }
  }
  
  span {
    display: inline-block;
    margin-left: 52px;
    padding: 8px 0;
    width: 52%;
  }

  &.active {
    background: rgba(0, 158, 226, .5);
  }

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`
export const ConfigLink = styled(NavLink)`
  bottom: 180px;
  color: var(--white);
  font-size: 25px;
  font-weight: 400;
  position: fixed;
  text-align: center;
  text-decoration: none;
  width: 278px;
  padding: 8px 0;

  &.active {
    background: rgba(0, 158, 226, .5);
  }

  @media screen and (max-width: 1440px) {
    bottom: 135px;
    font-size: 20px;
    width: 200px;
  }
`
export const WaterMark = styled.span`
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, .7);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  position: fixed;
  width: 278px;
  bottom: 52px;

  @media screen and (max-width: 1440px) {
    font-size: 14px;
    width: 200px;
  }
`