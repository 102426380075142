import React, { ChangeEvent, FormEvent, useEffect } from 'react'
import * as SC from './style'
import { FormGroup } from '../../../../GlobalStyles'
import { getRequestTransfer, resetRequestTransferState, updateRequestTransferState } from '../../../../redux/features/requestTransfer/requestTransferSlice'
import { REQUESTOR, REQ_TRANSFER } from '../../../../routes'
import { updateState } from '../../../../redux/features/requestor/requestorSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Form from './Form/Form'
import Modal from '../../../../components/Modal/Modal'
import Requirements from '../../../../components/Requirements/Requirements'
import { getTransferRequirements } from '../../../../services/configuration'
import { postTransfer } from '../../../../services/transfers'
import { AppDispatch } from '../../../../redux/app/store'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { IoClose } from 'react-icons/io5'

const RequestTransfer = () => {
  const dispatch = useDispatch<AppDispatch>()
  const requestTransfer = useSelector(getRequestTransfer)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getTransferRequirements())

    return (() => {
      dispatch(resetRequestTransferState())
    })
  }, [])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(postTransfer(requestTransfer)).unwrap()
      .then((data) => {
        showAlert("Traslado creado", "success")

        if (data.type_transfer === "990") {
          navigate(`${REQUESTOR}/990/${data.id}`)
          return
        }

        navigate(`${REQ_TRANSFER}/${data.id}`)
      }).catch((error) => {
        if (error.code === 400) return showAlert("Este paciente tiene un traslado activo", "info")
        showAlert("Error al crear el traslado", "error")
      })

    return

    if (requestTransfer.type_transfer === "Sencillo") {
      navigate(`${REQUESTOR}/sencillo`)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    dispatch(updateRequestTransferState({ name, value }))
  }

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "requestTransferModal", value: false }))}>
      <SC.RequestTransferWrapper onSubmit={handleSubmit}>
        <Form handleChange={handleChange} />
        <Requirements requirements={[requestTransfer.transfer_request, requestTransfer.mobilization_request]} labelLess={true} />
        <FormGroup totalWidth="calc(100% - 72px)" margin='15px 0 38px 0'>
          <label htmlFor="">Descripción</label>
          <textarea name='description' placeholder="Escribe aquí..." onChange={handleChange}></textarea>
        </FormGroup>
        <Button
          text='Solicitar'
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
        />
        <button onClick={(e) => e.preventDefault()} className='close modal-wrapper'><IoClose size={35} /></button>
      </SC.RequestTransferWrapper>
    </Modal >
  )
}

export default RequestTransfer