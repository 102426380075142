import React from 'react'
import * as SC from './style'

interface ToggleProps {
  active: number,
  setActive: (active: number) => void
}

const Toggle = ({ active, setActive }: ToggleProps) => {
  const handleSetActive = (e: any): void => {
    const value: string = e.target.dataset.active

    localStorage.setItem("active", value)

    setActive(Number(value))
  }

  return (
    <div>
      <SC.Button data-active="1" onClick={handleSetActive} active={active === 1}>Traslados pendientes</SC.Button>
      <SC.Button data-active="2" onClick={handleSetActive} active={active === 2}>Historico</SC.Button>
    </div>
  )
}

export default Toggle