import styled from "styled-components";

export const MessageStyled = styled.p`
  color: var(--text);
  font-size: 25px;
  font-weight: 400;
  padding: 50px 0;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`