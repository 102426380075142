import React from 'react'
import { useSelector } from 'react-redux'
import { FileNumber, FormGroup, FormWrapper, CustomGroups, Line } from '../../../../../GlobalStyles'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'
import Form from './Form/Form'

const T990 = () => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <FormWrapper className="add-margin-bottom">
        <FileNumber margin='0 0 18px 0'>{transfer.consecutive}</FileNumber>
        <Form />
        <FormGroup totalWidth='calc(100% - 72px)' margin='0 0 18px 0'>
          <label htmlFor="">Descripción</label>
          <textarea value={transfer.description || "-"} disabled></textarea>
        </FormGroup>
        <Line />
        <CustomGroups>
          <FormGroup big={true}>
            <label htmlFor="">Nombre acompañante</label>
            <input type="text" value={transfer?.t990?.companion || "-"} />
          </FormGroup>
          <FormGroup big={true} margin="0 0 28px 0">
            <label htmlFor="">Telefono acompañante</label>
            <input type="text" value={transfer?.t990?.companion_phone || "-"} />
          </FormGroup>
        </CustomGroups>
      </FormWrapper>
    </>
  )
}

export default T990