import React, { MouseEvent } from 'react'
import * as MS from '../../../../style'
import { FaMinusSquare } from 'react-icons/fa'
import { ITransferRequirement } from '../../../../../../redux/features/transferRequirements/interfaces'
import { showConfirmAlert } from '../../../../../../utils/alerts/confirmAlert'
import { useDispatch } from 'react-redux'
import { deleteTransferRequirement } from '../../../../../../services/configuration'
import { AppDispatch } from '../../../../../../redux/app/store'
import { showAlert } from '../../../../../../utils/alerts/showAlert'
import { TransferRequirementsEnum } from '../../../../../../utils/enum'

interface ItemProps {
  transferRequirement: ITransferRequirement
}

const Item = ({ transferRequirement }: ItemProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleRemoveClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const isConfirmed = await showConfirmAlert({
      title: "Estás seguro/a",
      text: "Está acción es irreversible",
      icon: "question"
    })

    if (!isConfirmed) return

    dispatch(deleteTransferRequirement({ requirementId: transferRequirement.id, type: TransferRequirementsEnum.movilization })).unwrap()
      .then(() => {
        showAlert("Requerimiento eliminado", "success")
      }).catch(() => showAlert("Error al eliminar el requerimiento", "error"))
  }

  return (
    <MS.ListItem>
      <span>{transferRequirement.name}</span>
      <button onClick={handleRemoveClick}>
        <FaMinusSquare size={26} color='var(--second-red)' />
      </button>
    </MS.ListItem>
  )
}

export default Item