export const transferInitialState = {
  id: "",
  patient: "",
  type_document: "",
  document: "",
  hab: "",
  type_transfer: "",
  origin: "",
  destination: "",
  hour: "",
  requeriments: [],
  description: "",
  annotation: null,
  consecutive: "",
  status: "",
  collected: null,
  delivered: null,
  applicant: "",
  calification: 0,
  t990: {
    id: "",
    date_of_death: "",
    death_certificate: "",
    case_covid19: "",
    funeral_service: false,
    companion: "",
    companion_phone: "",
  },
  t990_exit: {
    id: "",
    funeral_home_delivery: "",
    funeral_home_name: "",
    official_name_funeral_home: "",
    document: "",
    applicant: "",
    annotations: "",
  },
  assistant: {
    caDocument: ""
  }
}
export const request990TransferInitialState = {
  date_of_death: "",
  death_certificate: "",
  case_covid19: "",
  funeral_service: false,
  companion: "",
  companion_phone: ""
}