import React, { FormEvent, useState } from 'react'
import * as MS from '../../style'
import { AppDispatch } from '../../../../redux/app/store'
import { BsPlusSquareFill } from 'react-icons/bs'
import { postTransferRequirement } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { TransferRequirementsEnum } from '../../../../utils/enum'
import { useDispatch } from 'react-redux'
import List from './List/List'

const Movilization = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [state, setState] = useState<string>("")

  const handleAddMovilization = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!state) return showAlert("El requerimiento está vacío", "warning")

    dispatch(postTransferRequirement({
      name: state,
      type: TransferRequirementsEnum.movilization
    })).unwrap()
      .then(() => {
        showAlert("Requerimiento agregado", "success")
        setState("")
      }).catch(() => showAlert("Error al crear el requerimiento", "error"))
  }

  return (
    <MS.Square onSubmit={handleAddMovilization}>
      <MS.Title>Movilización</MS.Title>
      <MS.InputWrapper>
        <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
        <MS.AddButton><BsPlusSquareFill color='var(--light-blue)' size={55} /></MS.AddButton>
      </MS.InputWrapper>
      <List />
    </MS.Square>
  )
}

export default Movilization