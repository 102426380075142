import React, { MouseEvent } from 'react'
import * as SC from './style'

interface OptionsProps {
  option: number,
  setOption: (option: number) => void
}

const Options = ({ option, setOption }: OptionsProps) => {
  const handleOptionsClick = (e: MouseEvent<HTMLButtonElement>) => {
    // @ts-ignore
    const value = Number(e.target.dataset.option)
    setOption(value)
    localStorage.setItem("conf-active", String(value))
  }

  return (
    <SC.ConfOptionsWrapper>
      <SC.Option onClick={handleOptionsClick} active={option === 1} data-option="1">Areas</SC.Option>
      <SC.Option onClick={handleOptionsClick} active={option === 2} data-option="2">Asignaciones</SC.Option>
      <SC.Option onClick={handleOptionsClick} active={option === 3} data-option="3">Tiempo de traslado</SC.Option>
      <SC.Option onClick={handleOptionsClick} active={option === 4} data-option="4">Requerimientos de traslado</SC.Option>
    </SC.ConfOptionsWrapper>
  )
}

export default Options