import React, { useEffect } from 'react'
import * as SC from './style'

interface ModelProps {
  stateHandler: () => void,
  children: JSX.Element | JSX.Element[]
}

const Modal = ({ stateHandler, children }: ModelProps) => {
  useEffect(() => {
    return (() => stateHandler())
  }, [])

  const handleCloseModal = (e: any) => {
    const classes: string[] = e.target.classList

    if (Array.from(classes).includes("modal-wrapper")) {
      stateHandler()
    }
  }

  return (
    <SC.ModalWrapper onMouseDown={handleCloseModal}>{children}</SC.ModalWrapper>
  )
}

export default Modal