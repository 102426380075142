import styled from "styled-components";

export interface BtnProps {
  single?: boolean
}

export const ActionsWrapper = styled.div`  
  display: flex;
  flex-direction: column;
  width: fit-content;
  background: var(--white);
  position: absolute;
  right: 55px;
  bottom: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  padding: 17px 23px;
`
export const Btn = styled.button<BtnProps>`
  align-items: center;
  background: none;
  border: none;
  color: var(--black);
  cursor: pointer;
  display: flex;
  font-size: 25px;
  font-weight: 500;
  gap: 20px;
  justify-content: space-between;

  :not(:last-child) {
    border-bottom: 1px solid var(--dark-border);
    padding-bottom: 10px;
  }

  :last-child {
    padding: ${({ single }) => single ? "0px 20px" : "10px 0 0 0"};
  }

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`