import styled from "styled-components";

export const TotalTransfers = styled.span`
  color: var(--text);
  font-size: 20px;
  font-weight: 600;
  margin: 0 20px 0 15px;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`
export const Amount = styled.span`
  background: var(--white);
  border-radius: 5px;
  box-shadow: inset 0px 3px 6px var(--shadow);
  color: var(--text);
  display: inline-block;
  font-size: 33px;
  font-weight: 400;
  padding: 3px 34px;

  @media screen and (max-width: 1440px) {
    font-size: 28px;   
  }
`