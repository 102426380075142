import React from 'react'
import { AppDispatch } from '../../../redux/app/store'
import { BsPlusLg } from 'react-icons/bs'
import { FlexWrapper } from '../../../GlobalStyles'
import { postCodeBlue } from '../../../services/transfers'
import { showAlert } from '../../../utils/alerts/showAlert'
import { updateState } from '../../../redux/features/requestor/requestorSlice'
import { useDispatch } from 'react-redux'
import Button from '../../../components/Button/Button'

const Options = () => {
  const dispatch = useDispatch<AppDispatch>()

  const handleCodeBlueClick = () => {
    dispatch(postCodeBlue()).unwrap()
      .then(() => {
        showAlert("Código azul creado", "success")
      }).catch(() => showAlert("Error al crear el Código Azul", "error"))
  }

  return (
    <>
      <FlexWrapper>
        <Button
          text='Solicitar traslados'
          color='var(--white)'
          background='var(--light-blue)'
          onClick={() => dispatch(updateState({ name: "asignTransferModal", value: true }))}
        ><BsPlusLg /></Button>
        <Button
          text='Código Azul'
          color='var(--white)'
          background='var(--king-blue)'
          onClick={handleCodeBlueClick}
        />
      </FlexWrapper>
    </>
  )
}

export default Options