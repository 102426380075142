import styled from "styled-components";

export const ContentWrapper = styled.form`
  background: var(--modal-grey);
  border-radius: 15px;
  margin: calc(200px + 4vh) auto;
  padding: 121px 90px 52px 90px;
  position: relative;
  width: 858px;

  & > button.close-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 18px;
    top: 20px;

    svg {
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1440px) {
    margin: calc(4vh + 50px) auto;
  }
`