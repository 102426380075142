import styled from "styled-components";

export const Row = styled.div`
  align-items: center;
  display: flex;
  gap: 17px;
  margin-top: 26px;
`
export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
export const Name = styled.span`
  color: var(--text);
  font-size: 22px;
  font-weight: 600;
  margin-right: 17px;

  @media screen and (max-width: 1440px) {
    font-size: 17px;
  }
`
export const TotalTransfers = styled.span`
  background: var(--transfer-grey);
  border-radius: 26px;
  color: var(--white);
  display: inline-block;
  font-size: 23px;
  font-weight: 400;
  padding: 8px 15px;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
  }
`
export const Performance = styled(Name)`
  margin: 0;
`
export const PerformanceText = styled.span`
  color: var(--text);
  font-size: 25px;
  font-weight: 400;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`
export const List = styled.ul`
  list-style: none;
  margin-top: 59px;

  li {
    align-items: center;
    color: var(--text);
    display: flex;
    font-size: 25px;
    font-weight: 400;
    gap: 13px;
    
    :not(:last-child) {
      margin-bottom: 40px;
    }

    :first-child {
      span {
        background: var(--second-blue);
      }
    }

    :nth-child(2) {
      span {
        background: var(--purple);
      }
    }

    :last-child {
        span {
          background: var(--fuchsia);
        }
      }
    
    span {
      border-radius: 5px;
      display: inline-block;
      height: 28px;
      width: 28px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 20px;
    }
  }
`