import { configureStore } from "@reduxjs/toolkit";
import areasReducer from "../features/areas/areaSlice";
import assistantsReducer from "../features/assistants/assistansSlice";
import auxiliarieReducer from "../features/auxiliaries/auxiliarieSlice";
import pavilionReducer from "../features/pavilions/pavilionSlice";
import requestorReducer from "../features/requestor/requestorSlice";
import requestTransferReducer from "../features/requestTransfer/requestTransferSlice";
import transferReducer from "../features/transfers/transferSlice";
import TransferRequirementsReducer from "../features/transferRequirements/transferRequirementsSlice";
import travelTimeReducer from "../features/travelTime/travelTimeSlice";
import userReducer from "../features/users/userSlice";
import assignmentReducer from "../features/assignments/assignmentSlice";
import permissionReducer from "../features/permissions/permissionSlice";

export const store = configureStore({
  reducer: {
    areas: areasReducer,
    assistants: assistantsReducer,
    auxiliaries: auxiliarieReducer,
    pavilions: pavilionReducer,
    requestor: requestorReducer,
    requestTransfer: requestTransferReducer,
    transferRequirements: TransferRequirementsReducer,
    transfers: transferReducer,
    travelTimes: travelTimeReducer,
    users: userReducer,
    assignments: assignmentReducer,
    permissions: permissionReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch