import React, { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { IArea } from '../../../../redux/features/areas/interfaces'
import Actions from '../Actions/Actions'

interface RowProps {
  area: IArea,
}

const Row = ({ area }: RowProps) => {
  const [showActions, setShowActions] = useState(false)

  return (
    <div className="row">
      <span>{area.name}</span>
      <span>{area?.pavilions?.length ? area.pavilions.join(" / ") : ""}</span>
      <button className="dots" onClick={() => setShowActions(!showActions)}><BiDotsVerticalRounded color='var(--third-grey)' size={40} /></button>
      {showActions ? <Actions areaId={area.id} setShowActions={setShowActions} /> : null}
    </div>
  )
}

export default Row