import React from 'react'
import * as SC from './style'
import { GridLoader } from 'react-spinners'

const MainLoading = () => {
  return (
    <SC.MainLoadingWrapper>
      <GridLoader size={20} color="#073678" />
    </SC.MainLoadingWrapper>
  )
}

export default MainLoading