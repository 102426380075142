import React, { ChangeEvent } from 'react'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'
import { CustomGroups, FormGroup } from '../../../../../GlobalStyles'
import { IoIosArrowDown } from 'react-icons/io'
import { useSelector } from 'react-redux'

interface FormProps {
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const Form = ({ handleChange }: FormProps) => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={transfer.patient} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={transfer.type_document} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={transfer.document} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={transfer.hab} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={transfer.type_transfer} readOnly />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Fecha de fallecimiento</label>
          <input type="date" name='date_of_death' onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Acta de defunción </label>
          <input type="text" name='death_certificate' onChange={handleChange} required />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Caso COVID-19</label>
          <div>
            <select name="case_covid19" onChange={handleChange} required>
              <option value=""></option>
              <option value="No">No</option>
              <option value="Sospechoso">Sospechoso</option>
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Servicio funerario</label>
          <div>
            <select name="funeral_service" onChange={handleChange} required>
              <option value=""></option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
      </CustomGroups>
    </>
  )
}

export default Form