import { AUXILIARES } from "../../../../routes";
import { FileNumber, FormGroup, FormWrapper } from "../../../../GlobalStyles";
import { getTransfer } from "../../../../redux/features/transfers/transferSlice";
import { getTransferById } from "../../../../services/transfers";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as SC from "./style";
import Back from "../../../../components/Back/Back";
import Form from "./Form/Form";
import Requirements from "../../../../components/Requirements/Requirements";

const Request = () => {
  const dispatch = useDispatch();
  const transfer = useSelector(getTransfer);
  const { requestId } = useParams();

  useEffect(() => {
    dispatch(getTransferById(requestId!));
  }, []);

  return (
    <div className="addMarginBottom">
      <Back url={AUXILIARES} />
      <FormWrapper>
        <SC.StarWrapper>
          <FileNumber margin="0 27px 0 0">#100514</FileNumber>
          <Rating
            allowHalfIcon={true}
            readonly={true}
            transition={true}
            size={40}
            ratingValue={transfer.calification * 20}
            fillColor="#FFCE5D"
          />
        </SC.StarWrapper>
        <Form />
        <Requirements requirements={transfer.requeriments} />
        <FormGroup totalWidth="calc(100% - 72px)" margin="76px 0 35px 0">
          <label htmlFor="">Descripción</label>
          <textarea value={transfer.description || "-"} disabled></textarea>
        </FormGroup>
        <FormGroup totalWidth="calc(100% - 72px)" margin="0 0 57px 0">
          <label htmlFor="">Observaciones</label>
          <textarea value={transfer.annotation || "-"} disabled></textarea>
        </FormGroup>
      </FormWrapper>
    </div>
  );
};

export default Request;
