import React from 'react'
import { FormGroup, CustomGroups } from '../../../../../../GlobalStyles'
import { getTransfer } from '../../../../../../redux/features/transfers/transferSlice'
import { IoIosArrowDown } from 'react-icons/io'
import { useSelector } from 'react-redux'
import Requirements from '../../../../../../components/Requirements/Requirements'

const Form = () => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={transfer.patient || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={transfer.type_document || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={transfer.document || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={transfer.hab || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={transfer.type_transfer || "-"} />
        </FormGroup>
        {/* </CustomGroups>
      <Groups> */}
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={transfer.origin || "-"} />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={transfer.destination || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={transfer.hour || "-"} />
        </FormGroup>
      </CustomGroups>
      <Requirements requirements={transfer.requeriments} margin="0 0 33px 0" />
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Fecha de fallecimiento</label>
          <input type="text" value={transfer?.t990?.date_of_death || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Acta de defunción</label>
          <input type="text" value={transfer?.t990?.death_certificate || "-"} />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Caso COVID-19</label>
          <div>
            <select value={transfer?.t990?.case_covid19 || "-"}>
              <option value="">-</option>
              <option value="No">No</option>
              <option value="Sospechoso">Sospechoso</option>
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Servicio funerario</label>
          <div>
            <select value={transfer?.t990?.funeral_service === true ? "Si" : transfer?.t990?.funeral_service === false ? "No" : ""}>
              <option value="">-</option>
              <option value="Si">Sí</option>
              <option value="No">No</option>
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
      </CustomGroups>
    </>
  )
}

export default Form