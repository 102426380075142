import React, { useEffect } from "react";
import App from "./App";

const Redirect = () => {
  const tok = window.localStorage.getItem("access");

  useEffect(() => {
    const token = window.location.href.split("tk=")[1];

    if (tok && token) {
      window.location.href = process.env.REACT_APP_AUX_DNS!;
      return;
    }

    if (tok) return;

    if (!token) {
      window.location.href = process.env.REACT_APP_HUB_DNS!;
      return;
    }

    window.localStorage.setItem("access", token);
    window.location.href = process.env.REACT_APP_AUX_DNS!;
  }, []);

  return <>{tok ? <App /> : <h1></h1>}</>;
};

export default Redirect;
