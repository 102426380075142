import styled, { css } from "styled-components";

interface ArrowButtonProps {
  isOpened: boolean
}
interface AreaProps {
  isOpened: boolean
}

export const Wrapper = styled.div`
  background: var(--white);
  border-radius: 15px;
  padding: 28px 81px;
`
export const AssignmentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const Area = styled.p<AreaProps>`
  color: var(--black);
  font-size: 25px;
  font-weight: 400;
  max-width: 1100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ isOpened }) => isOpened ? "normal" : "nowrap"};

  span {
    font-weight: 200;
  }
  
  @media screen and (max-width: 1440px) {
    font-size: 20px;
    max-width: 750px;
  }
`
export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
`
export const EditButton = styled.button`
  background: var(--orange);
  border-radius: 12px;
  border: none;
  cursor: pointer;
  padding: 6px 7px 2px 7px;
`
export const ArrowButton = styled.button<ArrowButtonProps>`
  background: none;
  border: none;
  cursor: pointer;
  transition: all .4s ease;

  svg {
    transform: rotate(0deg);
  }

  ${({ isOpened }) => isOpened && css`
    transform: rotate(180deg);
  `}
`