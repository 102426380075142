import React, { ChangeEvent, useEffect } from 'react'
import { CustomFormGroup, FormGroup, Groups } from '../../../../../GlobalStyles'
import { getPatientState, getRequestTransfer, updateRequestTransferState } from '../../../../../redux/features/requestTransfer/requestTransferSlice'
import { getTransferRequirementsState } from '../../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { IoIosArrowDown } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHour } from '../../../../../hooks/useHour'
import { usePavilions } from '../../../../../hooks/usePavilions'
import Select, { StylesConfig } from 'react-select'

const selectStyles: StylesConfig = {
  container: (styles) => ({ ...styles, width: "100%" }),
  input: (styles) => ({ ...styles, padding: "9px" }),
  option: (styles) => ({ ...styles, fontSize: "16px" }),
  singleValue: (styles) => ({ ...styles, fontSize: "21px", padding: "0px", color: "var(--text)", textAlign: "center" }),
  control: (styles) => ({ ...styles, borderRadius: "8px", border: "1px solid var(--dark-border)" })
}

interface FormProps {
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
}

const Form = ({ handleChange }: FormProps) => {
  const dispatch = useDispatch()
  const patient = useSelector(getPatientState)
  const hour = useHour()
  const { movilization, ventilation } = useSelector(getTransferRequirementsState)
  const requestTransfer = useSelector(getRequestTransfer)
  const pavilionsDestination = usePavilions("destination")

  useEffect(() => {
    dispatch(updateRequestTransferState({ name: "destination", value: "MORGUE" }))
  }, [requestTransfer?.type_transfer])

  const handleSelect = (e: any) => {
    const { name, value } = e
    dispatch(updateRequestTransferState({ name, value }))
  }

  return (
    <>
      <Groups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" name='patient' value={`${patient?.fName || "-"} ${patient?.fLastname || "-"}`} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={patient?.tipDoc || "-"} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={patient?.document || "-"} readOnly />
        </FormGroup>
      </Groups>
      <Groups>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" name="hab" value={patient?.patientHabitation || "-"} readOnly />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Tipo de traslado</label>
          <div>
            <select name="type_transfer" onChange={handleChange} required>
              <option value="">-- vacío --</option>
              <option value="Redondo">Redondo</option>
              <option value="Sencillo">Sencillo</option>
              <option value="990">990</option>
              <option value="990 Salida">990 Salida</option>
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
        <FormGroup customWidth="600px">
          <label htmlFor="">Origen</label>
          <input type="text" name='origin' value={patient?.patientPavilion || "-"} readOnly />
        </FormGroup>
      </Groups>
      <Groups>
        <CustomFormGroup>
          <label htmlFor="">Destino</label>
          {requestTransfer?.type_transfer !== "990" ? (
            <Select options={pavilionsDestination} styles={selectStyles} onChange={handleSelect} />
          ) : (
            <Select options={pavilionsDestination} value={{
              label: "MORGUE",
              value: "MORGUE",
              name: "MORGUE"
            }} styles={selectStyles} onChange={handleSelect} isDisabled={true} />
          )}
        </CustomFormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Hora</label>
          <input type="time" name="hour" min={hour} onChange={handleChange} required />
        </FormGroup>
      </Groups>
      <Groups>
        <FormGroup big={true}>
          <label htmlFor="">Requerimiento de traslado</label>
          <div>
            <select name="mobilization_request" onChange={handleChange} required>
              <option value="">-- Seleccionar --</option>
              {movilization.map(item => (
                <option key={item.id} value={item.name}>{item.name}</option>
              ))}
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Requerimientos de ventilación</label>
          <div>
            <select name="transfer_request" onChange={handleChange}>
              <option value="">-- Seleccionar --</option>
              {ventilation.map(item => (
                <option key={item.id} value={item.name}>{item.name}</option>
              ))}
            </select>
            <IoIosArrowDown color='var(--icon-grey)' size={40} />
          </div>
        </FormGroup>
      </Groups>
    </>
  )
}

export default Form