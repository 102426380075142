import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { AiOutlineClear, AiOutlineSearch } from 'react-icons/ai'
import { Date, OptionsWrapper } from '../../../../../../GlobalStyles'
import { getAssistantStatisticsById } from '../../../../../../services/assistants'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Button from '../../../../../../components/Button/Button'

const Options = () => {
  const dispatch = useDispatch()
  const { auxiliaryId } = useParams<{ auxiliaryId: string }>()
  const [data, setData] = useState({
    initial_date: "",
    final_date: ""
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    setData({
      ...data,
      [name]: value
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(getAssistantStatisticsById({ auxiliaryId: auxiliaryId!, ...data }))
  }

  const handleClean = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(getAssistantStatisticsById({ auxiliaryId: auxiliaryId! }))
    setData({
      initial_date: "",
      final_date: ""
    })
  }

  return (
    <OptionsWrapper onSubmit={handleSubmit}>
      <Date
        type="text"
        name='initial_date'
        value={data.initial_date}
        placeholder='Fecha Inicial'
        onChange={handleChange}
        onFocus={(e) => e.target.type = "date"}
        onBlur={(e) => e.target.type = "text"}
      />
      <Date
        type="text"
        name='final_date'
        value={data.final_date}
        placeholder='Fecha Final'
        onChange={handleChange}
        onFocus={(e) => e.target.type = "date"}
        onBlur={(e) => e.target.type = "text"}
      />
      <Button
        text='Buscar'
        color='var(--white)'
        background='var(--light-blue)'
        boldText={true}
        padding="10px 28px"
        svgMargin="0 0 0 13px"
        onClick={() => { }}
      >
        <AiOutlineSearch size={35} />
      </Button>
      <Button
        text=''
        color='var(--white)'
        background='#838383'
        boldText={true}
        padding="8px 15px"
        svgMargin="0 0 0 0px"
        // @ts-ignore
        onClick={handleClean}
      ><AiOutlineClear size={40} /></Button>
    </OptionsWrapper>
  )
}

export default Options