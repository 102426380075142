import styled from "styled-components";

interface ItemProps {
  jusCon?: boolean,
  gap?: boolean
}
interface ItemsWrapperProps {
  small?: boolean
}

export const SquaresWrapper = styled.div`
  display: flex;
`
export const StyledSquare = styled.div`
  margin-top: 39px;
  width: 42%;

  :first-child {
    border-right: 1px solid rgba(112, 112, 112, .5);
    padding-right: 60px;
  }

  :last-child {
    padding-left: 60px;
  }
`
export const ItemsWrapper = styled.ul<ItemsWrapperProps>`
  list-style: none;
  max-height: 330px;
  max-height: ${({ small }) => small ? "252px" : "330px"};
  overflow: auto;
  padding-right: 25px;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(211, 208, 208, 0.8);
    border-radius: 10px;
  }
`
export const Item = styled.li<ItemProps>`
  align-items: center;
  color: var(--black);
  display: flex;
  font-size: 25px;
  font-weight: 200;
  gap: ${({ gap }) => gap ? "12px" : "unset"};
  justify-content: ${({ jusCon }) => jusCon ? "space-between" : "unset"};

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  :not(:first-child) {
    padding: 21px 0 9px 0;
  }

  :first-child {
    padding-bottom: 9px;
  }

  :not(:last-child) {
    border-bottom: 1px solid rgba(112, 112, 112, .5);
  }

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`
export const InputWrapper = styled.div`
  border-radius: 22px;
  border: 1px solid var(--dark-border);
  margin-bottom: 22px;
  position: relative;
  width: 80%;
  
  input {
    background: none;
    border-radius: 22px;
    border: none;
    color: var(--text);
    font-size: 25px;
    font-weight: 400;
    padding: 6px 25px;
    width: calc(100% - 50px);

    @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
  }

  svg {
    position: absolute;
    right: 25px;
    top: 3px;
  }
`