import { toast, ToastPosition } from "react-toastify"

const alertFunctions = {
  success: toast.success,
  warning: toast.warning,
  error: toast.error,
  info: toast.info,
  default: toast,
}

export const showAlert = (message: string, type: "success" | "warning" | "error" | "info" | "default", position: ToastPosition = "top-right", autoClose: number = 1500) => {
  alertFunctions[type](message, {
    position: position,
    autoClose: autoClose,
  })
}