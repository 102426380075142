import React from 'react'
import { useSelector } from 'react-redux'
import { FormGroup, CustomGroups } from '../../../../../GlobalStyles'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'

const Form = () => {
  const transfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={transfer.patient} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={transfer.type_document} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={transfer.document} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={transfer.hab} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={transfer.type_transfer} readOnly />
        </FormGroup>
        {/* </CustomGroups> */}
        {/* <Groups> */}
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={transfer.origin} readOnly />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={transfer.destination} readOnly />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={transfer.hour} readOnly />
        </FormGroup>
      </CustomGroups>
    </>
  )
}

export default Form