import React, { ChangeEvent, FormEvent, useState } from 'react'
import * as SC from './style'
import { AiOutlineSearch } from 'react-icons/ai'
import { AppDispatch } from '../../../redux/app/store'
import { getPatient } from '../../../services/patient'
import { IoClose } from 'react-icons/io5'
import { IoIosArrowDown } from 'react-icons/io'
import { getIsLoading, setRequestTrasnferPatient } from '../../../redux/features/requestTransfer/requestTransferSlice'
import { showAlert } from '../../../utils/alerts/showAlert'
import { updateState } from '../../../redux/features/requestor/requestorSlice'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Button/Button'
import Modal from '../../../components/Modal/Modal'
import MainLoading from '../../../components/MainLoading/MainLoading'

const AsignTransfer = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)
  const [data, setData] = useState({
    tipDoc: "",
    document: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(getPatient(data)).unwrap().then((response) => {
      dispatch(setRequestTrasnferPatient({
        type_document: response.tipDoc,
        document: response.document,
        hab: response.patientHabitation,
        patient: `${response.fName} ${response.fLastname}`,
        origin: response.patientPavilion
      }))
      dispatch(updateState({ name: "asignTransferModal", value: false }))
      dispatch(updateState({ name: "requestTransferModal", value: true }))
    }).catch(() => showAlert("No se encontró información del paciente", "error"))

  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target

    setData({ ...data, [name]: value })
  }

  return (
    <>
      <Modal stateHandler={() => dispatch(updateState({ name: "asignTransferModal", value: false }))}>
        <SC.AsignTransferWrapper onSubmit={handleSubmit}>
          <SC.Groups>
            <SC.Group>
              <label htmlFor="">Tipo de documento</label>
              <div>
                <select onChange={handleChange} name="tipDoc" required>
                  <option value=""></option>
                  <option value="CC">CC</option>
                  <option value="TI">TI</option>
                  <option value="PP">PP</option>
                  <option value="PE">PE</option>
                  <option value="RC">RC</option>
                  <option value="NIT">NIT</option>
                  <option value="SC">SC</option>
                </select>
                <IoIosArrowDown color='var(--icon-grey)' size={40} />
              </div>
            </SC.Group>
            <SC.Group>
              <label htmlFor="">N° Documento</label>
              <input type="number" name='document' placeholder='1234567895' onChange={handleChange} required />
            </SC.Group>
          </SC.Groups>
          <Button
            text='Busca'
            color='var(--white)'
            background='var(--light-blue)'
            svgMargin=' 0 0 0 12px'
            boldText={true}
          >
            <AiOutlineSearch size={35} />
          </Button>
          <button onClick={(e) => e.preventDefault()} className='close modal-wrapper'><IoClose size={35} /></button>
        </SC.AsignTransferWrapper>
      </Modal>
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default AsignTransfer