import React from 'react'
import * as SC from './style'
import { updateState } from '../../../../redux/features/requestor/requestorSlice'
import { useDispatch } from 'react-redux'
import Modal from '../../../../components/Modal/Modal'
import Button from '../../../../components/Button/Button'

const NoAssistant = () => {
  const dispatch = useDispatch()

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "noAssistantModal", value: false }))}>
      <SC.NoAssistantWrapper>
        <SC.MainText>Lo sentimos, en el momento no se encuentran auxiliares disponibles.</SC.MainText>
        <SC.SecondaryText>Tu solicitud puede tardar unos minutos</SC.SecondaryText>
        <Button
          text='Entendido'
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          onClick={() => dispatch(updateState({ name: "noAssistantModal", value: false }))}
        />
      </SC.NoAssistantWrapper>
    </Modal>
  )
}

export default NoAssistant