import React from 'react'
import { Code, UnauthorizedWrapper } from './style'

const Unauthorized = () => {
  return (
    <>
      <UnauthorizedWrapper>Unauthorized</UnauthorizedWrapper>
      <Code>401</Code>
    </>
  )
}

export default Unauthorized