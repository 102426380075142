import React, { useEffect, useState } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../redux/app/store'
import { AUXILIARES } from '../../routes'
import { getPavilions } from '../../services/pavilions'
import { MdArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import Select, { StylesConfig } from 'react-select'
import { getIsLoading } from '../../redux/features/pavilions/pavilionSlice'
import MainLoading from '../../components/MainLoading/MainLoading'

const selectStyles: StylesConfig = {
  container: (styles) => ({ ...styles, width: "100%" })
}

const MainPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)
  const [options, setOptions] = useState<any[]>([])

  const handleChange = (e: any) => {
    const { value } = e
    localStorage.setItem("pavilion", value)
  }

  useEffect(() => {
    dispatch(getPavilions()).unwrap()
      .then((data) => {
        const opts = data.map((item) => ({
          value: item.name,
          label: item.name
        }))
        setOptions(opts)
      })
  }, [])

  return (
    <>
      <SC.MainPageWrapper>
        <div>
          <SC.MainText>Seleccione el pabellón en el que se encuentra ubicado</SC.MainText>
          <Select options={options} styles={selectStyles} onChange={handleChange} />
          <SC.CustomLink to={AUXILIARES}>
            <MdArrowForwardIos color='var(--white)' size={30} />
          </SC.CustomLink>
        </div>
      </SC.MainPageWrapper>
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default MainPage