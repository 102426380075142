import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPavilion } from "../../redux/features/pavilions/interfaces";
import { rest } from "..";
import { RootState } from "../../redux/app/store";
import { urls } from "../urls";

export const getPavilions = createAsyncThunk(
  "pavilions/getPavilions",
  async () => {
    const url = `${urls.baseUrl}/get-services/all`
    const { data } = await rest.get<{ data: IPavilion[] }>(url)
    return data.data
  },
  {
    condition: (undefined, { getState }) => {
      const state = getState() as RootState

      if (state.pavilions.pavilions.length) return false
    }
  }
)