import React from 'react'
import * as SC from './style'

interface MessageProps {
  children: string
}

const Message = ({ children }: MessageProps) => {
  return (
    <SC.MessageStyled>{children}</SC.MessageStyled>
  )
}

export default Message