import React, { useEffect, useState } from 'react'
import * as SC from './style'
import { getAssistantStatisctics } from '../../../../../../../redux/features/assistants/assistansSlice'
import { useSelector } from 'react-redux'

const Graphs = () => {
  const assistantStatisctics = useSelector(getAssistantStatisctics)
  const [percentages, setPercentages] = useState({
    oportunos: 0,
    inoportunos: 0,
    rechazados: 0
  })

  useEffect(() => {
    setPercentages({
      oportunos: assistantStatisctics[3]?.totalTransfers * 100 / assistantStatisctics[0]?.transfers || 0,
      inoportunos: assistantStatisctics[4]?.totalTransfers * 100 / assistantStatisctics[0]?.transfers || 0,
      rechazados: assistantStatisctics[2]?.totalTransfers * 100 / assistantStatisctics[0]?.transfers || 0,
    })
  }, [assistantStatisctics])

  return (
    <SC.GraphsWrapper>
      <SC.Bars>
        <SC.BarWrapper height={percentages.oportunos}>
          <SC.Percentage percentage={percentages.oportunos}>{percentages.oportunos.toFixed(2)}%</SC.Percentage>
          <SC.Total>{assistantStatisctics[3]?.totalTransfers}</SC.Total>
          <div></div>
        </SC.BarWrapper>
        <SC.BarWrapper height={percentages.inoportunos}>
          <SC.Percentage percentage={percentages.inoportunos}>{percentages.inoportunos.toFixed(2)}%</SC.Percentage>
          <SC.Total>{assistantStatisctics[4]?.totalTransfers}</SC.Total>
          <div></div>
        </SC.BarWrapper>
        <SC.BarWrapper height={percentages.rechazados}>
          <SC.Percentage percentage={percentages.rechazados}>{percentages.rechazados.toFixed(2)}%</SC.Percentage>
          <SC.Total>{assistantStatisctics[2]?.totalTransfers}</SC.Total>
          <div></div>
        </SC.BarWrapper>
      </SC.Bars>
    </SC.GraphsWrapper>
  )
}

export default Graphs