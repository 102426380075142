import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 75%;
  margin-top: calc(4vh + 45px);
  margin-left: calc((100% - 75%) / 2 + (278px / 2));

  @media screen and (max-width: 1440px) {
    width: 80%;
    margin-left: calc((100% - 80%) / 2 + (200px / 2));
  }
`