import React, { ChangeEvent } from 'react'
import * as FS from '../../../AreaTable/Form/style'
import { getCurrentTravelTimeState, updateCurrentTravelTime } from '../../../../../redux/features/travelTime/travelTimeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { usePavilions } from '../../../../../hooks/usePavilions'
import { useTransferRequirements } from '../../../../../hooks/useTransferRequirements'
import Select, { StylesConfig } from 'react-select'

const selectStyles: StylesConfig = {
  container: (styles) => ({ ...styles, width: "100%" }),
  option: (styles) => ({ ...styles, fontSize: "16px" }),
  singleValue: (styles) => ({ ...styles, fontSize: "21px", padding: "0px", color: "var(--text)", textAlign: "center" }),
  input: (styles) => ({ ...styles, padding: "6px" })
}

const Form = () => {
  const dispatch = useDispatch()
  const travelTime = useSelector(getCurrentTravelTimeState)

  const handleSelectChange = (e: any) => {
    const { name, value } = e
    console.log(e, travelTime)
    dispatch(updateCurrentTravelTime({ name, value }))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, valueAsNumber } = e.target
    dispatch(updateCurrentTravelTime({ name, value: valueAsNumber }))
  }

  return (
    <>
      <FS.CustomGroups>
        <FS.CustomGroup>
          <label htmlFor="">Origen</label>
          <Select options={usePavilions("origin")} value={{
            label: travelTime.origin,
            value: travelTime.origin,
            name: "origin"
          }} styles={selectStyles} onChange={handleSelectChange} />
        </FS.CustomGroup>
        <FS.CustomGroup>
          <label htmlFor="">Destino</label>
          <Select options={usePavilions("destination")} value={{
            label: travelTime.destination,
            value: travelTime.destination,
            name: "destination"
          }} styles={selectStyles} onChange={handleSelectChange} />
        </FS.CustomGroup>
      </FS.CustomGroups>
      <FS.CustomGroups>
        <FS.CustomGroup>
          <label htmlFor="">Tipo</label>
          <Select options={useTransferRequirements()} value={{
            label: travelTime.type,
            value: travelTime.type,
            name: "type"
          }} styles={selectStyles} onChange={handleSelectChange} />
        </FS.CustomGroup>
        <FS.CustomGroup>
          <label htmlFor="">Tiempo aproximado</label>
          <input type="number" name="time" value={travelTime.time} min={0} onChange={handleChange} required />
        </FS.CustomGroup>
      </FS.CustomGroups>
    </>
  )
}

export default Form