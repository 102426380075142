import styled from "styled-components";

interface OptionProps {
  active: boolean
}

export const ConfOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 15% 28.3% 28.3% 28.3%;
  margin-top: 42px;
  width: 100%;
`
export const Option = styled.button<OptionProps>`
  background: ${({ active }) => active ? "var(--forth-grey)" : "rgba(198, 198, 198, .6)"};
  border: none;
  color: ${({ active }) => active ? "var(--white)" : "rgba(57, 57, 57, .5)"};
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  padding: 21px 0;
  position: relative;

  :first-child {
    border-radius: 15px 0 0 15px;
  }

  :last-child {
    border-radius: 0 15px 15px 0;
  }

  :not(:last-child) {
    ::after {
      content: "";
      position: absolute;
      height: 30px;
      background-color: rgba(57, 57, 57, .3);
      width: 2px;
      right: 0;
    }
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`