import styled from "styled-components";

export const Title = styled.span`
  color: var(--text);
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
  margin: 57px 0 71px 0;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`