import styled from "styled-components";

interface Ipaginate {
  marginTop?: string;
  marginBottom?: string;
}

export const PaginationWrapper = styled.div<Ipaginate>`
  margin-top: ${({ marginTop = "100px" }) => marginTop};
  margin-bottom: ${({ marginBottom = "50px" }) => marginBottom};

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    list-style: none;
  }
  
  .pageClassName {
    border-radius: 4px;
    display: block;
    /* padding: 9px 20px; */
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .1s ease-out;
    cursor: pointer;
  }

  .previousBtn, .nextBtn {
    color: grey;
    border-radius: 4px;
    font-size: 25px;    
    transition: all .1s ease-out;
    cursor: pointer;

    a {
      display: block;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    :hover {
      background: #ECECEC;
      border-radius: 50%;

      ::after {
        content: 'Anterior';
        position: absolute;
        top: 50px;
        padding: 5px;
        border-radius: 4px;
        font-size: 13px;
        left: -5px;
        display: block;
        background: grey;
        color: white;
      }
    }
  }

  .previousBtn {
    position: relative;

    :hover {
      ::after {
        content: 'Anterior';
      }
    }
  }

  .nextBtn {
    position: relative;

    :hover {
      ::after {
        content: 'Siguiente';
      }
    }
  }

  .active {
    color: var(--text);
    border-radius: 50%;
    font-weight: 300;
    border: none;
    border: 1px solid var(--dark-blue);
  }

  .disabled {
    background: #ececec;
    border-radius: 50%;
  }
`