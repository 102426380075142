import React from 'react'
import * as SC from './style'
import { AiFillCloseCircle } from 'react-icons/ai'
import { removePavilion } from '../../../../redux/features/areas/areaSlice'
import { useDispatch } from 'react-redux'

interface PavilionsProps {
  pavilions: string[]
}

const Pavilions = ({ pavilions }: PavilionsProps) => {
  const dispatch = useDispatch()

  const handleRemovePavilion = (e: any, pavilion: string) => {
    e.preventDefault()
    dispatch(removePavilion(pavilion))
  }

  return (
    <SC.PavilionsWrapper>
      {pavilions.map((pavilion) => (
        <SC.Pavilions key={pavilion}>
          <span>{pavilion}</span>
          <button onClick={(e) => handleRemovePavilion(e, pavilion)}><AiFillCloseCircle color='var(--white)' size={30} /></button>
        </SC.Pavilions>
      ))}
    </SC.PavilionsWrapper>
  )
}

export default Pavilions