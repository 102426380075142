import styled from "styled-components";

export const Square = styled.form`
  background: var(--white);
  border-radius: 22px;
  padding: 34px 32px;

  @media screen and (max-width: 1440px) {
    margin-bottom: 50px;
    width: 420px;
  }
`
export const Title = styled.p`
  display: block;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`
export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 11px;
  margin-bottom: 55px;

  input {
    border-radius: 7px;
    border: 0.5px solid #707070;
    color: var(--text);
    font-size: 25px;
    font-weight: 400;
    padding: 12px;
    width: 344px;

    @media screen and (max-width: 1440px) {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1440px) {
    margin-bottom: 10px;
  }
`
export const AddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 55px;
`
export const ListWrapper = styled.ul`
  list-style-type: none;
  max-height: 350px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(211, 208, 208, 0.8);
    border-radius: 10px;
    margin-block: 10px;
  }
`
export const ListItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  padding: 19px 0;

  span {
    color: var(--black);
    font-size: 25px;
    font-weight: 400;

    @media screen and (max-width: 1440px) {
      font-size: 20px;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  :not(:last-child) {
    border-bottom: 1px solid var(--dark-border);
  }
`
export const NoDataMeesage = styled.p`
  color: var(--text);
  font-size: 25px;
  text-align: center;
`