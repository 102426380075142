import React, { useEffect } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../redux/app/store'
import { getIsLoading } from '../../../redux/features/transferRequirements/transferRequirementsSlice'
import { getTransferRequirements } from '../../../services/configuration'
import { useDispatch, useSelector } from 'react-redux'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Movilization from './Movilization/Movilization'
import Ventilation from './Ventilation/Ventilation'

const TransferRequirements = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    dispatch(getTransferRequirements())
  }, [])

  return (
    <>
      <SC.TransferRequirementsWrapper>
        <Movilization />
        <Ventilation />
      </SC.TransferRequirementsWrapper>
      {isLoading ? (<MainLoading />) : null}
    </>
  )
}

export default TransferRequirements