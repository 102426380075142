import { IUser } from "../interfaces";

export const userInitialState: IUser = {
  active: false,
  details: {
    address: "",
    area: {
      id: 0,
      name: ""
    },
    attendant: false,
    city: "",
    country: "",
    id: "",
    phone: "",
    position: {
      id: 0,
      name: ""
    }
  },
  document: "",
  documentType: {
    id: 0,
    name: ""
  },
  email: "",
  id: "",
  lastname: "",
  name: ""
}