import React, { useEffect } from 'react'
import * as SC from './style'
import { GlobalStyles } from '../../GlobalStyles'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { MAIN } from '../../routes'

interface LayoutProps {
  children: JSX.Element | JSX.Element[]
}

const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const pavilion = localStorage.getItem("pavilion")
    if (!pavilion) {
      navigate(MAIN)
      return
    }
  }, [])

  return (
    <>
      <Header />
      {pathname === "/" ? null : <Sidebar />}
      <SC.MainWrapper>
        {children}
      </SC.MainWrapper>
      <GlobalStyles />
    </>
  )
}

export default Layout