import React from 'react'
import * as sc from './style'
import ReactPaginate from 'react-paginate'

interface PaginationProps {
  pageCount: number,
  page: number
  marginTop?: string,
  marginBottom?: string
  onPageChange: (data: { selected: number }) => void,
}

const Pagination = ({ pageCount, page, marginTop, marginBottom, onPageChange }: PaginationProps) => {
  return (
    <sc.PaginationWrapper marginTop={marginTop} marginBottom={marginBottom}>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageCount={pageCount}
        onPageChange={onPageChange}
        className={"container"}
        pageLinkClassName="pageClassName"
        previousClassName="previousBtn"
        nextClassName="nextBtn"
        activeClassName="active"
        disabledClassName="disabled"
        forcePage={page === 0 ? 0 : (page - 1)}
        // @ts-ignore
        renderOnZeroPageCount={null}
      />
    </sc.PaginationWrapper>
  )
}

export default Pagination