import React, { useEffect } from 'react'
import { getAsignTransferModal, getNoAssistantModal, getRequestTransferModal, updateState } from '../../redux/features/requestor/requestorSlice'
import { getIsLoading } from '../../redux/features/transfers/transferSlice'
import { useDispatch, useSelector } from 'react-redux'
import AsignTransfer from './AsignTransfer/AsignTransfer'
import MainLoading from '../../components/MainLoading/MainLoading'
import NoAssistant from './AsignTransfer/NoAssistant/NoAssistant'
import Options from './Options/Options'
import RequestorTable from './RequestorTable/RequestorTable'
import RequestTransfer from './AsignTransfer/RequestTransfer/RequestTransfer'
import { getPermissions } from '../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../components/Unauthorized/Unauthorized'

const Requestor = () => {
  const dispatch = useDispatch()
  const { access, admin, requestor } = useSelector(getPermissions)
  const isLoading = useSelector(getIsLoading)
  const asignTransferModal = useSelector(getAsignTransferModal)
  const requestTransferModal = useSelector(getRequestTransferModal)
  const noAssistantModal = useSelector(getNoAssistantModal)

  useEffect(() => {
    return (() => {
      dispatch(updateState({ name: "requestTransferModal", value: false }))
    })
  }, [])

  return (
    <>
      {
        requestor || access || admin ? (
          <>
            <Options />
            <RequestorTable />
            {asignTransferModal ? <AsignTransfer /> : null}
            {requestTransferModal ? <RequestTransfer /> : null}
            {noAssistantModal ? <NoAssistant /> : null}
          </>
        ) : (
          <Unauthorized />
        )
      }
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Requestor