import styled from "styled-components";

export const RequestTransferWrapper = styled.form`
  background: var(--modal-grey);
  border-radius: 15px;
  margin: calc(4vh + 5px) auto;
  padding: 25px 50px 25px 50px;
  position: relative;
  width: 868px;

  & > button.close {
    background: none;
    border: none;
    color: var(--text);
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      pointer-events: none;
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 0px 15px 15px 0;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(211, 208, 208, 0.8);
    border-radius: 0px 15px 15px 0;
  }

  @media screen and (max-width: 1440px) {
    border-radius: 15px 10px 10px 15px;
    max-height: 80vh;
    overflow: auto;
    padding: 46px 50px 23px 50px;
    width: 68%;
  }
`