import React, { FormEvent, useEffect } from 'react'
import * as TTS from '../AddTransferTime/style'
import { AppDispatch } from '../../../../redux/app/store'
import { getCurrentTravelTime, resetCurrentTravelTime, getCurrentTravelTimeState } from '../../../../redux/features/travelTime/travelTimeSlice'
import { IoClose } from 'react-icons/io5'
import { putTravelTime } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Button/Button'
import Form from './Form/Form'
import Modal from '../../../../components/Modal/Modal'

const EditTransferTime = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { id, ...travelTime } = useSelector(getCurrentTravelTimeState)

  useEffect(() => {
    const travelTime = localStorage.getItem("travelTimeId")
    dispatch(getCurrentTravelTime(travelTime!))

    return (() => {
      localStorage.removeItem("travelTimeId")
      dispatch(resetCurrentTravelTime())
    })
  }, [])

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(updateState({ name: "editTransferTime", value: false }))

    const id = localStorage.getItem("travelTimeId")

    if (!id) return showAlert("No hay información", "error")

    dispatch(putTravelTime({ body: travelTime, travelTimeId: id })).unwrap()
      .then(() => {
        localStorage.removeItem("travelTimeId")
        showAlert("Tiempo de traslado editado", "success")
      }).catch(() => showAlert("Error al editar el registro", "error"))
  }

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "editTransferTime", value: false }))}>
      <TTS.ContentWrapper onSubmit={handleSubmit}>
        <Form />
        <Button
          text='Editar'
          boldText={true}
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          marginTop="87px"
        />
        <button className="close-btn modal-wrapper"><IoClose size={35} className="modal-wrapper" /></button>
      </TTS.ContentWrapper>
    </Modal>
  )
}

export default EditTransferTime