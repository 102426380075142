import styled from "styled-components";
import { Link } from "react-router-dom";

export const BackButton = styled(Link)`
	align-items: center;
	background: none;
	color: var(--text);
	cursor: pointer;
	display: flex;
	font-size: 20px;
	gap: 8px;
	text-decoration: none;
	margin-bottom: 35px;
	width: fit-content;
  
	svg {
		transform: rotate(180deg);
	}
`