import React, { useEffect } from 'react'
import { AppDispatch } from '../../../redux/app/store'
import { getAssistants } from '../../../services/assistants'
import { getAuxiliaries, getIsLoading } from '../../../redux/features/auxiliaries/auxiliarieSlice'
import { showAlert } from '../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Table from '../../../components/Table/Table'
import { getPermissions } from '../../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../../components/Unauthorized/Unauthorized'

const AuxiliaresStatus = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { access, admin, administrator } = useSelector(getPermissions)
  const isLoading = useSelector(getIsLoading)
  const auxiliaries = useSelector(getAuxiliaries)

  useEffect(() => {
    dispatch(getAssistants()).unwrap()
      .catch(() => showAlert("Error al traer la información", "error"))
  }, [])

  return (
    <>
      {
        administrator || access || admin ? (
          <Table gridColumns='30% 17% 17% 17% 19%' height='75vh'>
            <div className="titles">
              <span>Auxiliar clínico</span>
              <span>Pabellón</span>
              <span>Rendimiento</span>
              <span>consecutivo</span>
              <span>Estado</span>
            </div>
            <div className="rows">
              {auxiliaries.map((auxiliary) => (
                <div className="row">
                  <span>{auxiliary.fullname || "-"}</span>
                  <span>{auxiliary.pavilion || "-"}</span>
                  <span>-</span>
                  <span>{auxiliary.consecutive || "-"}</span>
                  <div>
                    <span>{auxiliary.status || "-"}</span>
                    <span className={`${auxiliary.status === "Disponible" ? "available" : auxiliary.status === "Ocupado" ? "busy" : "scheduled"} status-circle`}></span>
                  </div>
                </div>
              ))}
            </div>
          </Table >
        ) : (
          <Unauthorized />
        )
      }
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default AuxiliaresStatus