import React from 'react'
import * as SC from './style'
import { getIsLoading } from '../../redux/features/assistants/assistansSlice'
import { useSelector } from 'react-redux'
import MainLoading from '../../components/MainLoading/MainLoading'
import Options from './Dashboard/Options/Options'
import PersonalList from './Dashboard/PersonalList/PersonalList'
import { getPermissions } from '../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../components/Unauthorized/Unauthorized'

const Admin = () => {
  const isLoading = useSelector(getIsLoading)
  const { access, admin, administrator } = useSelector(getPermissions)

  return (
    <>
      {
        administrator || access || admin ? (
          <>
            <Options />
            <SC.Title>Personal Auxiliares</SC.Title>
            <PersonalList />
          </>
        ) : (
          <Unauthorized />
        )
      }

      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Admin