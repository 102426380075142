import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteTransferRequirement, getTransferRequirements, postTransferRequirement } from "../../../services/configuration";
import { ITransferRequirement, ITransferRequirementsState } from "./interfaces";
import { RootState } from "../../app/store";
import { TransferRequirementsEnum } from "../../../utils/enum";

interface ITransferRequirementState {
  addAreaModal: boolean;
  addProximateTimeModal: boolean;
  editAreaModal: boolean;
  editTransferTime: boolean;
  isEditingAssignment: boolean;
  isLoading: boolean;
  transferRequirements: ITransferRequirementsState;
}

const initialState: ITransferRequirementState = {
  addAreaModal: false,
  addProximateTimeModal: false,
  editAreaModal: false,
  editTransferTime: false,
  isEditingAssignment: false,
  isLoading: false,
  transferRequirements: {
    movilization: [],
    ventilation: []
  }
}

const transferRequirementsSlice = createSlice({
  name: "transferRequirements",
  initialState,
  reducers: {
    updateState: (state, { payload }: PayloadAction<{ name: string, value: any }>) => {
      const { name, value } = payload
      // @ts-ignore
      state[name] = value
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransferRequirements.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTransferRequirements.fulfilled, (state, { payload }: PayloadAction<ITransferRequirement[]>) => {
        state.transferRequirements.movilization = payload.filter((tr) => tr.type === TransferRequirementsEnum.movilization)
        state.transferRequirements.ventilation = payload.filter((tr) => tr.type === TransferRequirementsEnum.ventilation)
        state.isLoading = false
      })
      .addCase(getTransferRequirements.rejected, (state) => {
        state.isLoading = false
        state.transferRequirements = initialState.transferRequirements
      })
    builder
      .addCase(postTransferRequirement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postTransferRequirement.fulfilled, (state, { payload }: PayloadAction<{ data: ITransferRequirement, type: string }>) => {
        const { data, type } = payload

        if (type === TransferRequirementsEnum.ventilation) {
          state.transferRequirements.ventilation.unshift(data)
          state.isLoading = false
          return
        }

        state.transferRequirements.movilization.unshift(data)
        state.isLoading = false
      })
      .addCase(postTransferRequirement.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteTransferRequirement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteTransferRequirement.fulfilled, (state, { payload }: PayloadAction<{ requirementId: string, type: string }>) => {
        if (payload.type === TransferRequirementsEnum.ventilation) {
          state.transferRequirements.ventilation = state.transferRequirements.ventilation.filter((item) => item.id !== payload.requirementId)
          state.isLoading = false
          return
        }

        state.transferRequirements.movilization = state.transferRequirements.movilization.filter((item) => item.id !== payload.requirementId)
        state.isLoading = false
      })
      .addCase(deleteTransferRequirement.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getAddAreaModal = (store: RootState) => store.transferRequirements.addAreaModal
export const getAddProximateTimeModal = (store: RootState) => store.transferRequirements.addProximateTimeModal
export const getEditAreaModal = (store: RootState) => store.transferRequirements.editAreaModal
export const getEditTransferTime = (store: RootState) => store.transferRequirements.editTransferTime
export const getIsEditingAssignment = (store: RootState) => store.transferRequirements.isEditingAssignment
export const getIsLoading = (store: RootState) => store.transferRequirements.isLoading
export const getTransferRequirementsState = (store: RootState) => store.transferRequirements.transferRequirements

export const { updateState } = transferRequirementsSlice.actions
export default transferRequirementsSlice.reducer