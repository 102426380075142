import React, { useEffect } from 'react'
import { ADMIN_DASHBOARD } from '../../../../../routes'
import { getAssistantStatisticsById } from '../../../../../services/assistants'
import { getIsLoading, resetAssistantStatistics } from '../../../../../redux/features/assistants/assistansSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Back from '../../../../../components/Back/Back'
import Content from './Content/Content'
import MainLoading from '../../../../../components/MainLoading/MainLoading'
import Options from './Options/Options'
import { getPermissions } from '../../../../../redux/features/permissions/permissionSlice'
import Unauthorized from '../../../../../components/Unauthorized/Unauthorized'

const Details = () => {
  const dispatch = useDispatch()
  const { access, admin, administrator } = useSelector(getPermissions)
  const isLoading = useSelector(getIsLoading)
  const { auxiliaryId } = useParams<{ auxiliaryId: string }>()

  useEffect(() => {
    dispatch(getAssistantStatisticsById({ auxiliaryId: auxiliaryId! }))

    return (() => {
      dispatch(resetAssistantStatistics())
    })
  }, [])

  return (
    <>
      {
        administrator || access || admin ? (
          <>
            <Back url={ADMIN_DASHBOARD} />
            <Options />
            <Content />
          </>
        ) : (
          <Unauthorized />
        )
      }

      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default Details