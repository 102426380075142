import React from 'react'
import * as SC from './style'
import Graphs from './Graphs/Graphs'
import Info from './Info/Info'

const Content = () => {
  return (
    <SC.ContentWrapper>
      <Info />
      <Graphs />
    </SC.ContentWrapper>
  )
}

export default Content