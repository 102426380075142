import React from 'react'
import { Label, Requirement, RequirementsWrapper } from '../../GlobalStyles'

interface RequirementsProps {
  requirements: string[],
  margin?: string,
  labelLess?: boolean
}

const Requirements = ({ requirements, margin = "39px 0 0 0", labelLess }: RequirementsProps) => {
  return (
    <RequirementsWrapper margin={margin}>
      {labelLess ? null : <Label>Requerimiento de traslado:</Label>}
      {
        requirements.map((requirement) => requirement ? <Requirement key={requirement}>{requirement}</Requirement> : null)
      }
    </RequirementsWrapper>
  )
}

export default Requirements