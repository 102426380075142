import { createAsyncThunk } from "@reduxjs/toolkit";
import { rest } from "..";
import { IPermission } from "../../redux/features/permissions/interfaces";
import { urls } from "../urls";

export const userHasPermissions = createAsyncThunk(
  "permissions/userHasPermissions",
  async () => {
    const url = `${urls.usersBaseUrl}/users/permissions`
    const response = await rest.get(url, {
      headers: {
        "bonadona-permissions": "auxclinic-administrator;auxclinic-requestor;auxclinic-auxiliary;auxclinic-access;auxclinic-admin"
      }
    })

    const data: IPermission[] = response.data.permissions.map((item: any) => ({ [item.nombre]: item.authorized }))

    return ({
      ...data[0],
      ...data[1],
      ...data[2],
      ...data[3],
      ...data[4],
    })
  }
)