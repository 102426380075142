import React, { useEffect, useState } from 'react'
import { getPermissions } from '../../redux/features/permissions/permissionSlice'
import { useSelector } from 'react-redux'
import HistoryTable from './HistoryTable/HistoryTable'
import PendingsTable from './PendingsTable/PendingsTable'
import Toggle from './Toggle/Toggle'
import Unauthorized from '../../components/Unauthorized/Unauthorized'

const Auxiliaries = () => {
  const [active, setActive] = useState<number>(Number(localStorage.getItem("active")) || 1)
  const { access, admin, auxiliary } = useSelector(getPermissions)

  return (
    <>
      {
        auxiliary || access || admin ? (
          <>
            <Toggle active={active} setActive={setActive} />
            {active === 1 ? (<PendingsTable />) : (<HistoryTable />)}
          </>
        ) : (
          <Unauthorized />
        )
      }
    </>
  )
}

export default Auxiliaries