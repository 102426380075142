import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPavilions } from "../../../services/pavilions";
import { RootState } from "../../app/store";
import { IPavilion } from "./interfaces";

interface IPavilionState {
  isLoading: boolean,
  pavilions: IPavilion[]
}

const initialState: IPavilionState = {
  isLoading: false,
  pavilions: []
}

const pavilionSlice = createSlice({
  name: "pavilion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPavilions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPavilions.fulfilled, (state, { payload }: PayloadAction<IPavilion[]>) => {
        state.pavilions = payload
        state.isLoading = false
      })
      .addCase(getPavilions.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.pavilions.isLoading
export const getPavilionState = (store: RootState) => store.pavilions.pavilions

export const { } = pavilionSlice.actions
export default pavilionSlice.reducer