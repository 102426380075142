import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPatient } from "../../../services/patient";
import { IPatient, IRequestTransfer } from "./interfaces";
import { patientInitialState, requestTransferInitialState } from "./state";
import { RootState } from "../../app/store";

interface IRequestTransferState {
  isLoading: boolean,
  requestTransfer: IRequestTransfer,
  patient: IPatient
}

const initialState: IRequestTransferState = {
  isLoading: false,
  requestTransfer: requestTransferInitialState,
  patient: patientInitialState
}

const requestTransferSlice = createSlice({
  name: "requestTransfer",
  initialState,
  reducers: {
    resetRequestTransferState: (state) => {
      state.requestTransfer = initialState.requestTransfer
    },
    updateRequestTransferState: (state, { payload }: PayloadAction<{ name: string, value: string }>) => {
      const { name, value } = payload

      state.requestTransfer = {
        ...state.requestTransfer,
        [name]: value
      }
    },
    setRequestTrasnferPatient: ((state, { payload }: PayloadAction<any>) => {
      state.requestTransfer = {
        ...state.requestTransfer,
        ...payload
      }
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatient.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPatient.fulfilled, (state, { payload }: PayloadAction<IPatient>) => {
        state.patient = payload
        state.isLoading = false
      })
      .addCase(getPatient.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.requestTransfer.isLoading
export const getRequestTransfer = (store: RootState) => store.requestTransfer.requestTransfer
export const getPatientState = (store: RootState) => store.requestTransfer.patient

export const { resetRequestTransferState, updateRequestTransferState, setRequestTrasnferPatient } = requestTransferSlice.actions
export default requestTransferSlice.reducer