import React, { useEffect, useState } from 'react'
import { getApplicantTransfer } from '../../../redux/features/transfers/transferSlice'
import { getApplicantTransfers } from '../../../services/transfers'
import { getUser } from '../../../redux/features/users/userSlice'
import { Link } from 'react-router-dom'
import { REQ_TRANSFER } from '../../../routes'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../../../components/Pagination/Pagination'
import Table from '../../../components/Table/Table'

const RequestorTable = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const applicantTransfer = useSelector(getApplicantTransfer)
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getApplicantTransfers({
      applicantId: user.document,
      page: page,
      quantity: 7
    }))
  }, [user, page])

  const handlePageChange = ({ selected }: any) => {
    setPage(selected + 1)
  }

  return (
    <>
      <Table gridColumns='30% 17.5% 17.7% 17.5% 17.5%'>
        <div className="titles">
          <span>Paciente</span>
          <span>Habitación</span>
          <span>Estado</span>
          <span>Tip. Traslado</span>
          <span>Hora Traslado</span>
        </div>
        <div className="rows">
          {
            applicantTransfer.results.map((transfer) => (
              <Link to={`${REQ_TRANSFER}/${transfer.id}`} key={transfer.id} className="row">
                <span>{transfer.patient}</span>
                <span>{transfer.hab}</span>
                <span>{transfer.status}</span>
                <span>{transfer.type_transfer}</span>
                <span>{Number(transfer.hour.split(":")[0]) >= 12 ? `${transfer.hour} PM` : `${transfer.hour} AM`}</span>
              </Link>
            ))
          }
        </div>
      </Table>
      <Pagination pageCount={applicantTransfer.totalPages} page={page} marginTop="50px" onPageChange={handlePageChange} />
    </>
  )
}

export default RequestorTable