import { useSelector } from "react-redux"
import { getTransferRequirementsState } from "../redux/features/transferRequirements/transferRequirementsSlice"

export const useTransferRequirements = () => {
  const { movilization, ventilation } = useSelector(getTransferRequirementsState)
  const items = [...movilization, ...ventilation]

  const options = items.map((item) => ({
    label: item.name,
    value: item.name,
    name: "type"
  }))

  return options
}