import styled from "styled-components";

interface TableWrapperProps {
  gridColumns?: string;
  height?: string;
}

export const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;

  div.titles {
    background: var(--second-grey);
    display: grid;
    grid-template-columns: ${({ gridColumns = "repeat(6, 1fr)" }) => gridColumns};
    padding: 25px 18px;
    border-radius: 13px 13px 0 0;
    margin-top: 3.7%;

    span {
      color: var(--text);
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      display: inline-block;

      :not(:last-child) {
        border-right: 1px solid var(--dark-border);
      }

      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }
    }
  }

  div.rows {
    background: var(--white);
    border-radius: 0 0 10px 10px;
    padding: 0 18px;
    box-shadow: 2px 2px 6px #0000003B;
    height: ${({ height }) => height};
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--blue);
      border-radius: 0 0 10px 0;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(211, 208, 208, 0.8);
      border-radius: 0 0 10px 0;
    }

    div.row, a.row {
      align-items: center;
      color: var(--text);
      display: grid;
      grid-template-columns: ${({ gridColumns = "repeat(6, 1fr)" }) => gridColumns};
      padding: 21px 0;
      text-decoration: none;

      :not(:last-child) {
        border-bottom: 1px solid var(--dark-border);
      }

      span {
        color: var(--text);
        font-size: 20px;
        text-align: center;
        font-weight: 400;

        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
      }

      div {
        color: var(--text);
        font-size: 20px;
        font-weight: 400;  
        position: relative;
        text-align: center;

        span.status-circle {
          border-radius: 50%;
          display: inline-block;
          height: 18px;
          position: absolute;
          right: 30px;
          top: 5px;
          width: 18px;

          &.available {
            background: var(--available-green);
          }

          &.busy {
            background: var(--busy-red);
          }

          &.scheduled {
            background: var(--scheduled-orange);
          }
        }

        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
      }
    }
  }
`