export const requestTransferInitialState = {
  patient: "",
  type_document: "",
  document: "",
  hab: "",
  type_transfer: "",
  origin: "",
  destination: "",
  hour: "",
  transfer_request: "",
  mobilization_request: "",
  description: "",
  applicant: "",
  returnee: false
}
export const patientInitialState = {
  document: "",
  tipDoc: "",
  fName: "",
  sName: "",
  fLastname: "",
  sLastname: "",
  gender: "",
  patientHabitation: "",
  patientPavilion: ""
}