import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { AppDispatch } from '../../../redux/app/store'
import { getAddProximateTimeModal, getEditTransferTime, updateState } from '../../../redux/features/transferRequirements/transferRequirementsSlice'
import { getIsLoading, getTravelTimesState } from '../../../redux/features/travelTime/travelTimeSlice'
import { getTravelTimes } from '../../../services/configuration'
import { showAlert } from '../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import AddTransferTime from './AddTransferTime/AddTransferTime'
import ConfigTable from '../../../components/ConfigTable/ConfigTable'
import EditTransferTime from './EditTransferTime/EditTransferTime'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Message from '../../../components/Message/Message'
import Pagination from '../../../components/Pagination/Pagination'
import Row from './Row/Row'

const TransferTimeTable = () => {
  const dispatch = useDispatch<AppDispatch>()
  const addProximateTimeModal = useSelector(getAddProximateTimeModal)
  const editTransferTime = useSelector(getEditTransferTime)
  const isLoading = useSelector(getIsLoading)
  const traverlTimes = useSelector(getTravelTimesState)
  const [page, setPage] = useState<number>(Number(localStorage.getItem("transfer-page")) || 0)
  const [curPage, setcurPage] = useState<number>(Number(localStorage.getItem("transfer-cur-page")) || 0)

  useEffect(() => {
    dispatch(getTravelTimes({ page, quantity: 6, curPage })).unwrap()
      .catch((code) => {
        if (code) return showAlert("No hay resultados...", "info")
        showAlert("Error al traer la infomación", "error")
      })
    setcurPage(page)
    localStorage.setItem("transfer-cur-page", String(page))
  }, [page])

  const handlePageChange = ({ selected }: any) => {
    setcurPage(page)
    localStorage.setItem("transfer-cur-page", String(page))

    const value = selected + 1
    localStorage.setItem("transfer-page", String(value))

    setPage(value)
  }

  return (
    <>
      <ConfigTable gridColumn='23.5% 23.5% 23.5% 23.5% 6%'>
        <div className="titles">
          <span>Origen</span>
          <span>Destino</span>
          <span>Tipo</span>
          <span>Tiempo aproximado</span>
          <button onClick={() => dispatch(updateState({ name: "addProximateTimeModal", value: true }))}><AiOutlinePlus color='var(--white)' size={30} /></button>
        </div>
        <div className="rows">
          {traverlTimes?.results?.length ? (
            traverlTimes.results.map((travelTime) => (
              <Row key={travelTime.id} travelTime={travelTime} />
            ))
          ) : <Message>No hay resultados...</Message>}
        </div>
      </ConfigTable>
      {addProximateTimeModal ? <AddTransferTime /> : null}
      {editTransferTime ? <EditTransferTime /> : null}
      {isLoading ? <MainLoading /> : null}
      <Pagination pageCount={traverlTimes.totalPages} page={page} marginTop="50px" onPageChange={handlePageChange} />
    </>
  )
}

export default TransferTimeTable