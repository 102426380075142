import styled from "styled-components";

interface ButtonWrapperProps {
  background: string;
  boldText?: boolean;
  center?: boolean
  color: string;
  fontSize?: string;
  svgMargin?: string;
  padding?: string;
  marginBottom?: string;
  marginTop?: string;
}

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  align-items: center;
  background: ${({ background = "red" }) => background};
  border-radius: 13px;
  border: none;
  box-shadow: 0px 3px 6px var(--shadow);
  color: ${({ color = "red" }) => color};
  cursor: pointer;
  display: flex;
  font-size: ${({ fontSize = "25px" }) => fontSize};
  font-weight: ${({ boldText }) => boldText ? "600" : "400"};
  margin: ${({ center = false }) => center ? "0 auto" : "0"};
  padding: ${({ padding = "13px 35px" }) => padding};
  margin-top: ${({ marginTop = 0 }) => marginTop};
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom};

  svg {
    margin: ${({ svgMargin = "0 0 0 20px" }) => svgMargin};
  }

  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
`