import React from 'react'
import * as SC from './style'

interface ButtonProps {
  background: string;
  boldText?: boolean;
  center?: boolean;
  children?: JSX.Element | JSX.Element[];
  color: string;
  fontSize?: string;
  marginBottom?: string;
  marginTop?: string;
  onClick?: () => void;
  padding?: string;
  svgMargin?: string;
  text: string;
}

const Button = ({ background, marginTop, marginBottom, padding, boldText, center, color, fontSize, svgMargin, text, children, onClick }: ButtonProps) => {
  return (
    <SC.ButtonWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      background={background}
      boldText={boldText}
      padding={padding}
      center={center}
      color={color}
      fontSize={fontSize}
      svgMargin={svgMargin}
      onClick={onClick}
    >{text} {children}</SC.ButtonWrapper>
  )
}

export default Button