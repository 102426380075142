import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteArea, getAreas, postArea, putArea } from "../../../services/configuration";
import { IArea, IGetAreas } from "./interfaces";
import { RootState } from "../../app/store";

interface IAreaState {
  isLoading: boolean,
  areas: IGetAreas
  area: IArea
}

const initialState: IAreaState = {
  isLoading: false,
  areas: {
    results: [],
    totalPages: 0,
    totalResults: 0
  },
  area: {
    id: "",
    name: "",
    pavilions: []
  }
}

const areaSlice = createSlice({
  name: "areas",
  initialState,
  reducers: {
    removePavilion: (state, { payload }: PayloadAction<string>) => {
      state.area.pavilions = state.area.pavilions.filter(item => item !== payload)
    },
    updateAreaName: (state, { payload }: PayloadAction<string>) => {
      state.area.name = payload
    },
    addPavilion: (state, { payload }: PayloadAction<string>) => {
      state.area.pavilions.unshift(payload)
    },
    setCurrentArea: (state, { payload }: PayloadAction<string>) => {
      state.area = state.areas.results.find((item) => item.id === payload)!
    },
    resetArea: (state) => {
      state.area = initialState.area
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAreas.fulfilled, (state, { payload }: PayloadAction<IGetAreas>) => {
        state.areas = payload
        state.isLoading = false
      })
      .addCase(getAreas.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteArea.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.areas.results = state.areas.results.filter((item) => item.id !== payload)
        state.isLoading = false
      })
      .addCase(deleteArea.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(postArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postArea.fulfilled, (state, { payload }: PayloadAction<IArea>) => {
        const currentPage = Number(localStorage.getItem("area-page"))

        if (state.areas.results.length < 6) {
          state.areas.results.push(payload)
        } else if ((state.areas.totalPages + 1) === currentPage + 1) {
          state.areas.totalPages = state.areas.totalPages + 1
        }

        state.isLoading = false
      })
      .addCase(postArea.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(putArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(putArea.fulfilled, (state, { payload }: PayloadAction<IArea>) => {
        const index = state.areas.results.findIndex((area) => area.id === payload.id)
        state.areas.results[index] = payload
        state.isLoading = false
      })
      .addCase(putArea.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.areas.isLoading
export const getAreaState = (store: RootState) => store.areas.areas
export const getCurrentArea = (store: RootState) => store.areas.area

export const { removePavilion, updateAreaName, addPavilion, setCurrentArea, resetArea } = areaSlice.actions
export default areaSlice.reducer