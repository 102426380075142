import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteTravelTime, getTravelTimes, postTravelTime, putTravelTime } from "../../../services/configuration";
import { IGetTravelTimes, IPostTravelTime, ITravelTime } from "./interfaces";
import { RootState } from "../../app/store";

interface ITravelTimeState {
  isLoading: boolean;
  travelTimes: IGetTravelTimes;
  travelTime: ITravelTime
}

const initialState: ITravelTimeState = {
  isLoading: false,
  travelTimes: {
    results: [],
    totalPages: 1,
    totalResults: 0
  },
  travelTime: {
    id: "",
    origin: "",
    destination: "",
    type: "",
    time: 0
  }
}

const travelTimeSlice = createSlice({
  name: "travelTime",
  initialState,
  reducers: {
    getCurrentTravelTime: (state, { payload }: PayloadAction<string>) => {
      state.travelTime = state.travelTimes.results.find((item) => item.id === payload)!
    },
    resetCurrentTravelTime: (state) => {
      state.travelTime = initialState.travelTime
    },
    updateCurrentTravelTime: (state, { payload }: PayloadAction<{ name: string, value: string | number }>) => {
      const { name, value } = payload
      state.travelTime = {
        ...state.travelTime,
        [name]: value
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTravelTimes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTravelTimes.fulfilled, (state, { payload }: PayloadAction<IGetTravelTimes>) => {
        state.travelTimes = payload
        state.isLoading = false
      })
      .addCase(getTravelTimes.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(postTravelTime.pending, (state) => {
        state.isLoading = true
      })
      .addCase(postTravelTime.fulfilled, (state, { payload }: PayloadAction<ITravelTime>) => {
        const currentPage = Number(localStorage.getItem("transfer-cur-page"))

        if (state.travelTimes.results.length < 6) {
          state.travelTimes.results.push(payload)
        } else if ((state.travelTimes.totalPages + 1) === currentPage + 1) {
          state.travelTimes.totalPages = state.travelTimes.totalPages + 1
        }

        state.isLoading = false
      })
      .addCase(postTravelTime.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(putTravelTime.pending, (state) => {
        state.isLoading = true
      })
      .addCase(putTravelTime.fulfilled, (state, { payload }: PayloadAction<{ body: IPostTravelTime, travelTimeId: string }>) => {
        const editedTravelTime = { ...payload.body, id: payload.travelTimeId }
        const index = state.travelTimes.results.findIndex((item) => item.id === payload.travelTimeId)
        state.travelTimes.results[index] = editedTravelTime
        state.isLoading = false
      })
      .addCase(putTravelTime.rejected, (state) => {
        state.isLoading = false
      })
    builder
      .addCase(deleteTravelTime.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteTravelTime.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.travelTimes.results = state.travelTimes.results.filter((item) => item.id !== payload)
        state.isLoading = false
      })
      .addCase(deleteTravelTime.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export const getIsLoading = (store: RootState) => store.travelTimes.isLoading
export const getTravelTimesState = (store: RootState) => store.travelTimes.travelTimes
export const getCurrentTravelTimeState = (store: RootState) => store.travelTimes.travelTime

export const { getCurrentTravelTime, resetCurrentTravelTime, updateCurrentTravelTime } = travelTimeSlice.actions
export default travelTimeSlice.reducer