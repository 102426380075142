import styled from "styled-components";

export const MainLoadingWrapper = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, .5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`