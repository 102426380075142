import styled from "styled-components";

export const ModalWrapper = styled.div`
  background: rgba(245, 245, 245, .6);
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 278px);
  z-index: 500;
`