import React, { ChangeEvent } from 'react'
import * as SC from './style'
import { addPavilion, getCurrentArea, updateAreaName } from '../../../../redux/features/areas/areaSlice'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import { usePavilions } from '../../../../hooks/usePavilions'
import Select, { StylesConfig } from 'react-select'

const selectStyles: StylesConfig = {
  container: (styles) => ({ ...styles, width: "100%" }),
  input: (styles) => ({ ...styles, padding: "6px" }),
  option: (styles) => ({ ...styles, fontSize: "16px" }),
  singleValue: (styles) => ({ ...styles, fontSize: "21px", padding: "0px", color: "var(--text)", textAlign: "center" }),
}

const Form = () => {
  const dispatch = useDispatch()
  const area = useSelector(getCurrentArea)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateAreaName(e.target.value))
  }

  const handleSelectChange = (e: any) => {
    if (area.pavilions.includes(e.value)) return showAlert("Este pabellón ya está agregado", "info")
    dispatch(addPavilion(e.value))
  }

  return (
    <SC.CustomGroups>
      <SC.CustomGroup>
        <label htmlFor="">Nombre de Area</label>
        <input type="text" value={area.name} placeholder='Escribe aquí...' onChange={handleChange} />
      </SC.CustomGroup>
      <SC.CustomGroup>
        <label htmlFor="">Pabellones</label>
        <Select options={usePavilions("pavilions")} styles={selectStyles} onChange={handleSelectChange} />
      </SC.CustomGroup>
    </SC.CustomGroups>
  )
}

export default Form