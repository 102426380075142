import React, { FormEvent } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../../redux/app/store'
import { getCurrentTravelTimeState, resetCurrentTravelTime } from '../../../../redux/features/travelTime/travelTimeSlice'
import { IoClose } from 'react-icons/io5'
import { postTravelTime } from '../../../../services/configuration'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { updateState } from '../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Button/Button'
import Form from './Form/Form'
import Modal from '../../../../components/Modal/Modal'

const AddTransferTime = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { id, ...travelTime } = useSelector(getCurrentTravelTimeState)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!travelTime.origin || !travelTime.destination || !travelTime.type || !travelTime.time) return showAlert("Campos vacíos", "warning")

    dispatch(updateState({ name: "addProximateTimeModal", value: false }))

    dispatch(postTravelTime(travelTime)).unwrap()
      .then(() => {
        dispatch(resetCurrentTravelTime())
        showAlert("Tiempo de traslado creado", "success")
      }).catch(() => showAlert("Error al crear el registro", "error"))
  }

  return (
    <Modal stateHandler={() => dispatch(updateState({ name: "addProximateTimeModal", value: false }))}>
      <SC.ContentWrapper onSubmit={handleSubmit}>
        <Form />
        <Button
          text='Crear'
          boldText={true}
          background='var(--light-blue)'
          color='var(--white)'
          center={true}
          marginTop="87px"
        />
        <button className="close-btn modal-wrapper"><IoClose size={35} className="modal-wrapper" /></button>
      </SC.ContentWrapper>
    </Modal>
  )
}

export default AddTransferTime