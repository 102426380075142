import styled from "styled-components";

export const NoAssistantWrapper = styled.div`
  background: var(--modal-grey);
  border-radius: 15px;
  margin: 300px auto 50px auto;
  padding: 69px 127px 40px 127px;
  width: 540px;
`
export const MainText = styled.p`
  color: var(--text);
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`
export const SecondaryText = styled(MainText)`
  font-weight: 400;
  font-size: 22px;
  margin: 47px 0;
`