import { AUXILIARES } from '../../../../routes'
import { FormWrapper, FileNumber, FormGroup } from '../../../../GlobalStyles'
import { getTransfer } from '../../../../redux/features/transfers/transferSlice'
import { getTransferById } from '../../../../services/transfers'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Back from '../../../../components/Back/Back'
import Form from './Form/Form'
import Requirements from '../../../../components/Requirements/Requirements'

const Request = () => {
  const dispatch = useDispatch()
  const trasnfer = useSelector(getTransfer)
  const { requestId } = useParams()

  useEffect(() => {
    dispatch(getTransferById(requestId!))
  }, [])

  return (
    <>
      <Back url={AUXILIARES} />
      <FormWrapper className='add-margin-bottom'>
        <FileNumber margin='0 0 18px 0'>#100514</FileNumber>
        <Form />
        <Requirements requirements={trasnfer.requeriments} />
        <FormGroup totalWidth="calc(100% - 72px)" margin='24px 0 100px 0'>
          <label htmlFor="">Descripción</label>
          <textarea value={trasnfer.description} disabled></textarea>
        </FormGroup>
      </FormWrapper>
    </>
  )
}

export default Request