import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IGetHistoricalTransfers,
  IGetPendingTransfers,
  IGetTransfers,
  ITransferDetails,
} from "../../redux/features/transfers/interface";
import { IRequestTransfer } from "../../redux/features/requestTransfer/interfaces";
import { rest } from "..";
import { RootState } from "../../redux/app/store";
import { urls } from "../urls";

export const getTransfers = createAsyncThunk(
  "transfers/getTransfers",
  async (queries: {
    page: number;
    quantity: number;
    initial_date: string;
    final_date: string;
    type_transfer: string;
  }) => {
    const { page, quantity, initial_date, final_date, type_transfer } = queries;
    const url = `${urls.baseUrl}/transfers?page=${page}&quantity=${quantity}&initial_date=${initial_date}&final_date=${final_date}&type_transfer=${type_transfer}`;
    const { data } = await rest.get<{ data: IGetTransfers }>(url);
    return data.data;
  }
);
export const getTransferById = createAsyncThunk(
  "transfers/getTransferById",
  async (transferId: string, { getState }) => {
    const state = getState() as RootState;

    if (state.transfers.transfer.document) return state.transfers.transfer;

    const url = `${urls.baseUrl}/transfers/${transferId}`;
    const { data } = await rest.get<{ data: ITransferDetails }>(url);
    return data.data;
  }
);
export const postTransfer = createAsyncThunk(
  "transfers/postTransfer",
  async (body: IRequestTransfer, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const url = `${urls.baseUrl}/transfers/create`;

    const { transfer_request, ...restt } = body;

    const newBody = { ...restt };

    // @ts-ignore
    if (body.transfer_request) newBody.transfer_request = body.transfer_request;

    try {
      const { data } = await rest.post<{ data: ITransferDetails }>(url, {
        ...newBody,
        applicant: state.users.user.document,
      });
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getApplicantTransfers = createAsyncThunk(
  "transfers/getApplicantTransfers",
  async (
    params: { applicantId: string; page?: number; quantity?: number },
    { getState }
  ) => {
    const state = getState() as RootState;

    if (!state.users.user.document) return state.transfers.applicantTransfer;

    const { applicantId, page = "", quantity = "" } = params;
    const url = `${urls.baseUrl}/transfers/applicant/all/${applicantId}?page=${page}&quantity=${quantity}`;
    const { data } = await rest.get(url);
    return data.data;
  }
);
export const post990Transfer = createAsyncThunk(
  "transfers/post990Transfer",
  async (transferId: string, { getState }) => {
    const state = getState() as RootState;
    const url = `${urls.baseUrl}/transfers/create/990/${transferId}`;
    const { data } = await rest.post(url, state.transfers.request990Transfer);
    return data.data;
  }
);
export const postCodeBlue = createAsyncThunk(
  "transfers/postCodeBlue",
  async () => {
    const pavilion = localStorage.getItem("pavilion");
    const url = `${urls.baseUrl}/transfers/create/code-blue`;
    await rest.post(url, { pavilion });
  }
);
export const getAssistantHistoricalTransfers = createAsyncThunk(
  "transfers/getAssistantHistoricalTransfers",
  async (params: { document: string; page?: number; quantity?: number }) => {
    const { document, page, quantity } = params;
    const url = `${urls.baseUrl}/transfers/historical/assistant?document=${document}&page=${page}&quantity=${quantity}`;
    const { data } = await rest.get<{ data: IGetHistoricalTransfers }>(url);
    console.log(data.data);
    return data.data;
  }
);
export const postReturnTransfer = createAsyncThunk(
  "transfers/postReturnTransfer",
  async (body: IRequestTransfer) => {
    const url = `${urls.baseUrl}/transfers/create`;

    const { data } = await rest.post<{ data: ITransferDetails }>(url, body);
    console.log(data);
    return data.data;
  }
);
export const getTransfersByArea = createAsyncThunk(
  "transfers/getTransfersByArea",
  async (params: { page?: number; quantity?: number }, { getState }) => {
    const state = getState() as RootState;
    const { page = 1, quantity = 8 } = params;

    const url = `${urls.baseUrl}/transfers/area/all/${state.auxiliaries.auxiliary.area}?page=${page}&quantity=${quantity}`;
    const { data } = await rest.get<{ data: IGetPendingTransfers }>(url);
    return data.data;
  }
);
export const cancelTransfer = createAsyncThunk(
  "transfers/cancelTransfer",
  async () => {
    const data = await rest.get(`${urls.baseUrl}/hola`);
    console.log(data);
  }
);
