import { getLoggedUser } from "./services/users";
import { HashRouter, Route, Routes } from "react-router-dom"
import { routes } from './routes'
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Layout from "./components/Layout/Layout"
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/src/sweetalert2.scss'
import { getLoggedAssistant } from "./services/assistants";
import { AppDispatch } from "./redux/app/store";
import { getIsLoading } from "./redux/features/auxiliaries/auxiliarieSlice";
import MainLoading from "./components/MainLoading/MainLoading";
import { getPavilions } from "./services/pavilions";
import { userHasPermissions } from "./services/permissions";

const App = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    dispatch(getLoggedUser()).unwrap()
      .then((data) => {
        dispatch(getLoggedAssistant(data.document))
      })
    dispatch(getPavilions())

    dispatch(userHasPermissions())
  }, [])

  return (
    <>
      <HashRouter>
        <Layout>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Layout>
        <ToastContainer />
      </HashRouter >
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default App
