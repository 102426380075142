import React, { ChangeEvent, useState } from 'react'
import * as MS from '../style'
import { BsPlusSquare } from 'react-icons/bs'
import { getAuxiliaries } from '../../../../../../redux/features/auxiliaries/auxiliarieSlice'
import { getIsEditingAssignment } from '../../../../../../redux/features/transferRequirements/transferRequirementsSlice'
import { IoSearchOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { IAuxiliry } from '../../../../../../redux/features/auxiliaries/interfaces'
import { addAssistantToAssignment } from '../../../../../../services/configuration'
import { IAssignment } from '../../../../../../redux/features/assignments/interfaces'
import { AppDispatch } from '../../../../../../redux/app/store'
import { showAlert } from '../../../../../../utils/alerts/showAlert'

interface SecondProps {
  assignment: IAssignment
}

const Second = ({ assignment }: SecondProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const isEditingAssignment = useSelector(getIsEditingAssignment)
  const auxiliaries = useSelector(getAuxiliaries)
  const [filtered, setFiltered] = useState<IAuxiliry[]>([])

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setFiltered(auxiliaries.filter((aux) => aux.fullname.toLowerCase().includes(value.toLocaleLowerCase())))
  }

  const handleAddAssistant = (assistantsId: string) => {
    dispatch(addAssistantToAssignment({ areaId: assignment.id, assistantsId })).unwrap()
      .then(() => { showAlert("Auxiliar agregado", "success") })
      .catch(() => showAlert("Error al agregar el auxiliar", "error"))
  }

  return (
    <MS.StyledSquare>
      <MS.InputWrapper>
        <input type="text" placeholder='Buscar' onChange={handleFilter} />
        <IoSearchOutline size={30} color="#AEAEAE" />
      </MS.InputWrapper>
      <MS.ItemsWrapper small={true}>
        {
          auxiliaries?.length ? (
            filtered.length ? (
              filtered.map((aux) => (
                <MS.Item key={aux.id} jusCon={true}>{aux.fullname} {isEditingAssignment ? (<button onClick={() => handleAddAssistant(aux.id)}><BsPlusSquare size={25} color="var(--text)" /></button>) : null}</MS.Item>
              ))
            ) : (
              auxiliaries.map((aux) => (
                <MS.Item key={aux.id} jusCon={true}>{aux.fullname} {isEditingAssignment ? (<button onClick={() => handleAddAssistant(aux.id)}><BsPlusSquare size={25} color="var(--text)" /></button>) : null}</MS.Item>
              ))
            )
          ) : null
        }
      </MS.ItemsWrapper>
    </MS.StyledSquare>
  )
}

export default Second