import React, { useEffect, useState } from 'react'
import { getAssistants } from '../../services/assistants'
import { getPavilions } from '../../services/pavilions'
import { getPermissions } from '../../redux/features/permissions/permissionSlice'
import { getTransferRequirements } from '../../services/configuration'
import { useDispatch, useSelector } from 'react-redux'
import AreaTable from './AreaTable/AreaTable'
import Assignments from './Assignments/Assignments'
import Options from './Options/Options'
import TransferRequirements from './TransferRequirements/TransferRequirements'
import TransferTimeTable from './TransferTimeTable/TransferTimeTable'
import Unauthorized from '../../components/Unauthorized/Unauthorized'

const Configuration = () => {
  const dispatch = useDispatch()
  const [option, setOption] = useState(Number(localStorage.getItem("conf-active")) || 1)
  const { access, admin, administrator } = useSelector(getPermissions)

  useEffect(() => {
    dispatch(getPavilions())
    dispatch(getTransferRequirements())
    dispatch(getAssistants())

    return (() => {
      localStorage.removeItem("transfer-page")
      localStorage.removeItem("conf-active")
      localStorage.removeItem("transfer-cur-page")
      localStorage.removeItem("transfer-page")
      localStorage.removeItem("area-cur-page")
      localStorage.removeItem("area-page")
      localStorage.removeItem("assignment-page")
      localStorage.removeItem("assignment-cur-page")
    })
  }, [])

  return (
    <>
      {
        administrator || access || admin ? (
          <>
            <Options option={option} setOption={setOption} />
            {option === 1 ? (<AreaTable />) : null}
            {option === 2 ? (<Assignments />) : null}
            {option === 3 ? (<TransferTimeTable />) : null}
            {option === 4 ? (<TransferRequirements />) : null}
          </>
        ) : (
          <Unauthorized />
        )
      }
    </>
  )
}

export default Configuration