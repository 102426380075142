import { AppDispatch } from '../../../redux/app/store'
import { cancelTransfer, getTransferById } from '../../../services/transfers'
import { getIsLoading, getTransfer, resetTransfer } from '../../../redux/features/transfers/transferSlice'
import { getPermissions } from '../../../redux/features/permissions/permissionSlice'
import { REQUESTOR } from '../../../routes'
import { showAlert } from '../../../utils/alerts/showAlert'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Back from '../../../components/Back/Back'
import Button from '../../../components/Button/Button'
import MainLoading from '../../../components/MainLoading/MainLoading'
import Round from './Round/Round'
import Simple from './Simple/Simple'
import Unauthorized from '../../../components/Unauthorized/Unauthorized'

const RequestedTransfer = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { access, admin, requestor } = useSelector(getPermissions)
  const isLoading = useSelector(getIsLoading)
  const transfer = useSelector(getTransfer)
  const navigate = useNavigate()

  const { transferId } = useParams<{ transferId: string }>()

  useEffect(() => {
    dispatch(getTransferById(transferId!))

    return (() => {
      dispatch(resetTransfer())
    })
  }, [])

  const handleCancelTransfer = () => {
    dispatch(cancelTransfer())
      .then(() => {
        // navigate(REQUESTOR)
        showAlert("Traslado cancelado", "success")
      })
      .catch(() => showAlert("Error al cancelar el traslado", "error"))
  }

  return (
    <>
      {
        requestor || access || admin ? (
          <>
            <Back url={REQUESTOR} />
            {transfer.type_transfer === "Sencillo" ? <Simple /> : null}
            {transfer.type_transfer === "Redondo" ? <Round /> : null}
            {transfer.type_transfer === "990 Salida" ? <Simple /> : null}
            {transfer.type_transfer === "990" ? <Simple /> : null}
            {transfer.type_transfer ? (
              <Button
                text='Cancelar traslado'
                color='var(--white)'
                background='var(--red)'
                center={true}
                marginTop="25px"
                marginBottom='50px'
                onClick={handleCancelTransfer}
              />
            ) : null}
          </>
        ) : (
          <Unauthorized />
        )
      }
      {isLoading ? <MainLoading /> : null}
    </>
  )
}

export default RequestedTransfer