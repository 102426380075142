import React, { useEffect, useState } from 'react'
import * as SC from './style'
import { AppDispatch } from '../../../../redux/app/store'
import { getAssistansStatistics } from '../../../../redux/features/assistants/assistansSlice'
import { getAssistantsStatistics } from '../../../../services/assistants'
import { useDispatch, useSelector } from 'react-redux'
import Personal from './Personal/Personal'

const PersonalList = () => {
  const dispatch = useDispatch<AppDispatch>()
  const assistansStatistics = useSelector(getAssistansStatistics)
  const [max, setMax] = useState<number>(0)

  useEffect(() => {
    dispatch(getAssistantsStatistics({})).unwrap()
      .then((data) => {
        const percentages = data.statistics.map((stat) => stat.totalTransfers)
        setMax(Math.max(...percentages))
      })
  }, [])

  return (
    <SC.PersonalListWrapper>
      {assistansStatistics.statistics.map((assistansStatistic) => (
        <Personal
          key={assistansStatistic.id}
          assistansStatistic={assistansStatistic}
          max={max}
        />
      ))}
    </SC.PersonalListWrapper>
  )
}

export default PersonalList