import styled from "styled-components";

export const PersonalListWrapper = styled.div`
  direction: rtl;
  max-height: 500px;
  overflow: auto;
  padding-left: 20px;
  width: 67%;

  div {
    direction: ltr;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(211, 208, 208, 0.8);
    border-radius: 10px;
    margin-block: 10px;
  }

  @media screen and (max-width: 1440px) {
    margin-bottom: 50px;
  }
`