import React, { ChangeEvent, FormEvent } from 'react'
import { AiOutlineClear, AiOutlineSearch } from 'react-icons/ai'
import { AppDispatch } from '../../../../redux/app/store'
import { Date, OptionsWrapper, SelectWrapper } from '../../../../GlobalStyles'
import { getTransfers } from '../../../../services/transfers'
import { IoIosArrowDown } from 'react-icons/io'
import { IOptions } from '../../../../redux/features/transfers/interface'
import { showAlert } from '../../../../utils/alerts/showAlert'
import { useDispatch } from 'react-redux'
import Button from '../../../../components/Button/Button'

interface OptionsProps {
  data: IOptions,
  setData: (data: IOptions) => void
}

const Options = ({ data, setData }: OptionsProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target

    setData({
      ...data,
      [name]: value
    })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(getTransfers({ ...data })).unwrap()
      .catch(() => showAlert("No hay información", "info"))
  }

  const handleClean = () => {
    setData({
      ...data,
      initial_date: "",
      final_date: "",
      type_transfer: ""
    })

    dispatch(getTransfers({ page: data.page, quantity: data.quantity, initial_date: "", final_date: "", type_transfer: "" }))
  }

  return (
    <OptionsWrapper onSubmit={handleSubmit}>
      <Date
        type="text"
        name='initial_date'
        value={data.initial_date}
        placeholder='Fecha Inicial'
        onChange={handleChange}
        onFocus={(e) => e.target.type = "date"}
        onBlur={(e) => e.target.type = "text"}
      />
      <Date
        type="text"
        name='final_date'
        value={data.final_date}
        placeholder='Fecha Final'
        onChange={handleChange}
        onFocus={(e) => e.target.type = "date"}
        onBlur={(e) => e.target.type = "text"}
      />
      <SelectWrapper>
        <select name="type_transfer" value={data.type_transfer} onChange={handleChange}>
          <option value="">Tipo de traslado</option>
          <option value="Sencillo">Sencillo</option>
          <option value="Redondo">Redondo</option>
          <option value="990">990</option>
          <option value="990 Salida">990 Salida</option>
        </select>
        <IoIosArrowDown color='var(--icon-grey)' size={25} />
      </SelectWrapper>
      <Button
        text='Buscar'
        color='var(--white)'
        background='var(--light-blue)'
        boldText={true}
        padding="10px 28px"
        svgMargin="0 0 0 13px"
        onClick={() => { }}
      >
        <AiOutlineSearch size={35} />
      </Button>
      <Button
        text=''
        color='var(--white)'
        background='#838383'
        boldText={true}
        padding="8px 15px"
        svgMargin="0 0 0 0px"
        onClick={handleClean}
      ><AiOutlineClear size={40} /></Button>
    </OptionsWrapper>
  )
}

export default Options