import { getPavilionState } from "../redux/features/pavilions/pavilionSlice";
import { useSelector } from "react-redux";

export const usePavilions = (name: string) => {
  const pavilions = useSelector(getPavilionState)

  const options = pavilions.map((opt) => ({
    label: opt.name,
    value: opt.name,
    name
  }))

  return options
}