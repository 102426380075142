import styled from "styled-components";

interface ConfigTableWrapperProps {
  gridColumn?: string
}

export const ConfigTableWrapper = styled.div<ConfigTableWrapperProps>`
  margin-top: 38px;

  div.titles {
    background: rgba(198, 198, 198, .6);
    border-radius: 15px 15px 0 0;
    display: grid;
    grid-template-columns: ${({ gridColumn = "47% 47% 6%" }) => gridColumn};
    padding: 9px 30px;
    position: relative;

    span {
      color: var(--black);
      font-size: 1.3vw;
      font-weight: 500;
      padding: 8px 0;
      text-align: center;

      :not(:last-of-type) {
        border-right: 2px solid var(--dark-border);
      }
    }

    button {
      background: var(--light-blue);
      border-radius: 0 15px 0 0;
      border: none;
      box-shadow: 0px 3px 6px var(--shadow);
      cursor: pointer;
      padding: 20px 0 12px 0;
      position: absolute;
      right: 0;
      width: 6%;

      @media screen and (max-width: 1440px) {
        padding: 13px 0 12px 0;
      }
    }
  }

  div.rows {
    background: var(--white);
    border-radius: 0 0 15px 15px;
    box-shadow: 2px 2px 6px #0000003B;
    padding: 0 30px;
    
    div.row {
      display: grid;
      grid-template-columns: ${({ gridColumn = "47% 47% 6%" }) => gridColumn};
      position: relative;
      
      :not(:last-child) {
        border-bottom: 1px solid var(--dark-border);
      }
      
      span {
        color: var(--black);
        font-size: 1.3vw;
        font-weight: 400;
        text-align: center;
        padding: 24px 0;
      }

      button.dots {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
`