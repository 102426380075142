import styled, { createGlobalStyle } from "styled-components";

interface FormGroupProps {
  big?: boolean;
  margin?: string;
  small?: boolean;
  totalWidth?: string;
  customWidth?: string
}
interface RequirementsWrapperProps {
  margin?: string;
}
interface FileNumberProps {
  margin?: string;
}

export const GlobalStyles = createGlobalStyle`
  :root {
    --available-green: #2DCD2D;
    --background: #F5F5F5;
    --black: #000000;
    --blue: #0F5ACB;
    --king-blue: #1905a0;
    --busy-red: #EE1818;
    --dark-blue: #073678;
    --dark-border: #707070;
    --forth-grey: #939393;
    --fuchsia: #CB29B0;
    --green: #24823D;
    --grey: #ECECEC;
    --icon-grey: #8A8A8A;
    --light-blue: #009EE2;
    --light-grey: #E2E2E2;
    --modal-grey: #D3D3D3;
    --orange: #F2BB23;
    --pavilion-grey: #6A6B7A;
    --purple: #3D3583;
    --red: #E8543D;
    --scheduled-orange: #F1B91D;
    --second-blue: #008AC0;
    --second-grey: #C6C6C6;
    --second-red: #E63D3D;
    --shadow: #00000029;
    --text: #393939;
    --third-grey: #A0A0A0;
    --transfer-grey: #747474;
    --white: #FFFFFF;
    --sea-blue: #364966;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    background: var(--background);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
  }

  textarea, button {
    font-family: "Poppins", sans-serif;
  }

  .addMarginBottom {
    margin-bottom: 50px;
  }
`
export const FormWrapper = styled.form`
  background: var(--white);
  border-radius: 15px;
  border-radius: 15px;
  box-shadow: 2px 2px 6px #0000003B;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.23);
  padding: 25px 72px;

  &.add-margin-bottom {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 1440px) {
    margin-bottom: 50px;
  }
`
export const FileNumber = styled.span<FileNumberProps>`
  color: var(--text);
  display: inline-block;
  font-size: 30px;
  font-weight: 500;
  margin: ${({ margin = "0" }) => margin};

  @media screen and (max-width: 1440px) {
    font-size: 25px;
  }
`
export const Groups = styled.div`
  display: flex;
  gap: 27px;

  :not(:last-of-type) {
    margin-bottom: 36px;
  }
`
export const CustomGroups = styled(Groups)`
  flex-wrap: wrap;
`
export const FormGroup = styled.div<FormGroupProps>`
  columns: var(--text);
  display: flex;
  flex-direction: column;
  font-size: 20px;
  width: ${({ big, small }) => big ? "385px" : small ? "180px" : "200px"};
  width: ${({ customWidth }) => customWidth};
  // The next line is for textareas
  margin: ${({ margin = "0" }) => margin};
  width: ${({ totalWidth }) => totalWidth};

  label {
    color: var(--text);
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  input {
    background: var(--white);
    border-radius: 8px;
    border: 1px solid var(--dark-border);
    font-size: 20px;
    font-weight: 400;
    padding: 14px 35px;
    text-align: center;

    &[type="time"] {
      padding: 12px 30px;
    }

    @media screen and (max-width: 1440px) {
      padding: 12px 20px;
      font-size: 14px;
    }
  }

  textarea {
    background: var(--white);
    border-radius: 8px;
    border: 1px solid var(--dark-border);
    color: var(--text);
    font-size: 20px;
    font-weight: 400;
    min-height: 100px;
    padding: 33px 36px;
    resize: vertical;
    width: 100%;

    @media screen and (max-width: 1440px) {
      font-size: 16px;
      min-height: 70px;
    }
  }

  div {
    background: var(--white);
    border-radius: 8px;
    border: 1px solid var(--dark-border);
    position: relative;
    
    select {
      appearance: none;
      background: none;
      border: none;
      color: var(--text);
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      padding: 14px 35px;
      width: 100%;

      @media screen and (max-width: 1440px) {
        font-size: 16px;
        padding: 12px 35px;
      }
    }

    svg {
      pointer-events: none;
      position: absolute;
      right: 15px;
      top: 5px;

      @media screen and (max-width: 1440px) {
        top: 1px;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    width: ${({ big, small }) => big ? "355px" : small ? "150px" : "170px"};
    width: ${({ customWidth }) => customWidth};

    textarea {
      width: 557px;
    }
  }
`
export const CustomFormGroup = styled.div<FormGroupProps>`
columns: var(--text);
  display: flex;
  flex-direction: column;
  font-size: 20px;
  width: 100%;
  margin: ${({ margin = "0" }) => margin};
  width: ${({ totalWidth }) => totalWidth};

  label {
    color: var(--text);
    font-weight: 500;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`
export const RequirementsWrapper = styled.div<RequirementsWrapperProps>`
  align-items: center;
  display: flex;
  gap: 15px;
  margin: ${({ margin = "0" }) => margin};
`
export const Label = styled.span`
  color: var(--text);
  display: inline-block;
  font-size: 20px;
  font-weight: 500;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`
export const Requirement = styled.span`
  background: var(--light-blue);
  border-radius: 18px;
  color: var(--white);
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  padding: 5px 22px;

  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
`
export const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 31px;
`
export const Line = styled.div`
  background: var(--dark-border);
  height: 1px;
  margin-bottom: 25px;
`
export const Date = styled.input`
  background: var(--white);
  border-radius: 8px;
  border: none;
  box-shadow: 0px 3px 3px #00000024;
  color: var(--text);
  font-size: 20px;
  font-weight: 300;
  padding: 15px;
  width: 200px;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    width: 150px;
  }
`
export const SelectWrapper = styled.div`
  background: var(--white);
  display: inline-block;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 3px 3px #00000024;
  
  select {
    appearance: none;
    background: none;
    border: none;
    color: var(--text);
    font-size: 20px;
    font-weight: 300;
    padding: 15px 50px 15px 15px;
    width: 100%;
    cursor: pointer;

    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
  }

  svg {
    position: absolute;
    top: 13px;
    right: 15px;
    pointer-events: none;
  }
`
export const OptionsWrapper = styled.form`
  display: flex;
  align-items: center;
  gap: 25px;
`