import React from "react";
import * as SC from "./style";
import { getAssistantStatisctics } from "../../../../../../../redux/features/assistants/assistansSlice";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Info = () => {
  const assistantStatisctics = useSelector(getAssistantStatisctics);
  const { auxiliary } = useParams<{ auxiliary: string }>();

  return (
    <div>
      <SC.NameWrapper>
        <SC.Name>{auxiliary?.replaceAll("-", " ")}</SC.Name>
        <SC.TotalTransfers>
          {assistantStatisctics[0]?.transfers} Traslados
        </SC.TotalTransfers>
      </SC.NameWrapper>
      <SC.Row>
        <SC.Performance>Rendiemiento:</SC.Performance>
        <SC.PerformanceText>
          {assistantStatisctics[1]?.performance < 50
            ? "Bajo"
            : assistantStatisctics[1]?.performance >= 50 &&
              assistantStatisctics[1]?.performance <= 80
            ? "Medio"
            : assistantStatisctics[1]?.performance > 80
            ? "Alto"
            : ""}
        </SC.PerformanceText>
        <Rating
          allowHalfIcon={true}
          size={30}
          ratingValue={assistantStatisctics[1]?.performance}
          fillColor="#FFCE5D"
          readonly={true}
        />
      </SC.Row>
      <SC.List>
        <li>
          <span></span> Oportunos
        </li>
        <li>
          <span></span> Inoportunos
        </li>
        <li>
          <span></span> Rechazados
        </li>
      </SC.List>
    </div>
  );
};

export default Info;
