import { useSelector } from 'react-redux'
import { Groups, FormGroup, CustomGroups } from '../../../../../GlobalStyles'
import { getTransfer } from '../../../../../redux/features/transfers/transferSlice'

const Form = () => {
  const trasnfer = useSelector(getTransfer)

  return (
    <>
      <CustomGroups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={trasnfer.patient} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={trasnfer.type_document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={trasnfer.document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={trasnfer.hab} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={trasnfer.type_transfer} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={trasnfer.origin} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={trasnfer.destination} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={trasnfer.hour} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Solicitante</label>
          <input type="text" value={trasnfer.applicant} disabled />
        </FormGroup>
      </CustomGroups>
      {/* <Groups>
        <FormGroup big={true}>
          <label htmlFor="">Paciente</label>
          <input type="text" value={trasnfer.patient} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tip. Documento</label>
          <input type="text" value={trasnfer.type_document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">N° Documento</label>
          <input type="text" value={trasnfer.document} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Habitación</label>
          <input type="text" value={trasnfer.hab} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo de traslado</label>
          <input type="text" value={trasnfer.type_transfer} disabled />
        </FormGroup>
      </Groups>
      <Groups>
        <FormGroup big={true}>
          <label htmlFor="">Origen</label>
          <input type="text" value={trasnfer.origin} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Destino</label>
          <input type="text" value={trasnfer.destination} disabled />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Hora</label>
          <input type="text" value={trasnfer.hour} disabled />
        </FormGroup>
        <FormGroup big={true}>
          <label htmlFor="">Solicitante</label>
          <input type="text" value={trasnfer.applicant} disabled />
        </FormGroup>
      </Groups> */}
    </>
  )
}

export default Form